import React from "react";
import NosPartenaires from "../../components/NosPartenaires";
import institutImage from "../../images/new/institut.png"; // Importation statique de l'image

export default function ESISA_International() {

  return (
    <div className="course_single_heading" style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
   

            <div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px" }}>
            {" "}
            L'ESISA ET L'INTERNATIONAL
          </span>
        </h4>
      </div>


      <div
        className="course-rating"
        style={{
          overflowY: "auto",
          maxHeight: "1000px",
          overflowX: "hidden",
          lineHeight: "1.6",
        }}
      >
        <p>
          Rejoindre l'ESISA, c'est s'engager dans une expérience académique enrichissante et ouvrir les portes d'une carrière internationale. Notre école, forte de partenariats avec des institutions prestigieuses à travers le monde, notamment en France et aux États-Unis, offre à ses étudiants une plateforme unique pour développer leurs compétences à l'échelle globale.
        </p>
        
        <h5 style={{ color: "#1962e5", margin: "2rem 0" , fontWeight:"bold"}}>
          Etudiants de l'ESISA admis aux différents partenaires en France et à l'international (Année universitaire 2024/2025)
        </h5>
        
        <div className="video-container2" style={{ textAlign: "center", marginBottom: "20px" }}>
          <video
            controls
            style={{
              maxWidth: "263px",
              height: "47rem",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <source src={require("../../images/new/admis.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <p>
          L'ESISA encourage vivement ses étudiants à réaliser des stages à l'international, qu'il s'agisse de la France, des États-Unis, ou d'autres pays. Grâce à une formation rigoureuse en anglais et en français, nos étudiants sont parfaitement préparés pour s'intégrer et exceller dans divers environnements professionnels, qu'ils choisissent de faire leur stage au Maroc ou dans n'importe quel autre pays.
        </p>
        
        <p>En 3ème année, les étudiants ont l'opportunité d'effectuer un stage de deux à trois mois.</p>
        <p>En 5ème année, ils peuvent réaliser un stage de quatre à six mois.</p>
        
        <p>
          Nous mettons un accent particulier sur la maîtrise de l'anglais pour garantir à nos étudiants les compétences linguistiques nécessaires à leur réussite dans un contexte international. Que ce soit pour un stage, un emploi en CDI, ou la poursuite d'études dans des pays anglophones ou francophones, nos étudiants sont préparés à relever tous les défis. En fin de 4ème année, ils sont notamment formés pour atteindre un niveau avancé au TOEIC (Test of English for International Communication).
        </p>
        
        <p>
          De plus, grâce à notre convention avec l'Institut Français, nos étudiants ont l'opportunité d'obtenir une certification en français de niveau B2, voire C1, facilitant ainsi leur admission dans nos écoles d'ingénieurs et universités partenaires en France.
        </p>
        
        <div className="image-container" style={{ textAlign: "center", marginTop: "20px" }}>
          <img 
            src={institutImage} // Utilisation de l'image importée statiquement
            alt="Institut Français"
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
        </div>
        <br/>
        <p>
          Chaque année, un grand nombre de nos étudiants de 4ème année poursuivent leurs études en France grâce à nos partenaires ou dans d'autres universités de renom à l'étranger.
        </p>
      </div>
    </div>
  );
}
