import { React, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import NavbarMob from "./components/NavbarMob";
import Contactinfo from "./components/Contactinfo";
import Footer from "./components/footer";
import Footerstrip from "./components/footerstrip";
import Accueil from "./Page/Accueil";
import Slider from "./components/Slider/Slider";
import EtudAdmis from "./Page/EtudAdmis";
import Mot_president from "./Page/Apropos/Mot_president";

import Conferences_ESISA from "./Page/Apropos/Conferences_ESISA";
import Conferences_etranger from "./Page/Apropos/Conferences_etranger";
import Etudier_ESISA from "./Page/Apropos/Etudier_ESISA";
import Objectifs_ESISA from "./Page/Apropos/Objectifs_ESISA";
import Themes_recherche from "./Page/Apropos/Themes_recherche";
import Voix_ESISA from "./Page/Apropos/Voix_ESISA";
import Workshop from "./Page/Apropos/Workshop";
import Systeme_enseignement from "./Page/Enseignement/Systeme_enseignement";
import Procedure_admission from "./Page/Enseignement/Procedure_admission";
import Bac3 from "./Page/Enseignement/Bac3";
import Bac5ISI from "./Page/Enseignement/Bac5ISI";
import Bac5ISD from "./Page/Enseignement/Bac5ISD";
import Bac5ISITN from "./Page/Enseignement/Bac5ISITN";
import Bac5IS2IA from "./Page/Enseignement/Bac5IS2IA";
import Ccna from "./Page/Enseignement/Ccna";

import ESISA_International from "./Page/International/ESISA_International";
import Admis_Etranger from "./Page/International/Admis_Etranger";
import Nos_Partenaires from "./Page/International/Nos_Partenaires";

import ESISAEntreprises from "./Page/EntreprisesStages/ESISAEntreprises";
import Stages from "./Page/EntreprisesStages/Stages";
import Emplois from "./Page/EntreprisesStages/Emplois";
import SocietesPartenaires from "./Page/EntreprisesStages/SocietesPartenaires";

import Galerie from "./Page/Autres/Galerie";
import Presse from "./Page/Autres/Presse";

import Preinscription from "./Page/Preinscription/Preinscription";

import Galimg1 from "./Page/Autres/Galerie1/Galimg1";
import Galimg2 from "./Page/Autres/Galerie1/Galimg2";
import Galimg3 from "./Page/Autres/Galerie1/Galimg3";
import Galimg4 from "./Page/Autres/Galerie1/Galimg4";
import Galimg5 from "./Page/Autres/Galerie1/Galimg5";
import Galimg6 from "./Page/Autres/Galerie1/Galimg6";
import Galimg7 from "./Page/Autres/Galerie1/Galimg7";
import Galimg8 from "./Page/Autres/Galerie1/Galimg8";
import Galimg9 from "./Page/Autres/Galerie1/Galimg9";
import Galimg10 from "./Page/Autres/Galerie1/Galimg10";
import Galimg11 from "./Page/Autres/Galerie1/Galimg11";
import Galimg12 from "./Page/Autres/Galerie1/Galimg12";
import Galimg13 from "./Page/Autres/Galerie1/Galimg13";
function App() {

  useEffect(() => {
    // Définir la fonction gtag dans la fenêtre globale
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // Configurer gtag avec votre ID Google Ads
    gtag('config', 'AW-16632715756');
  }, []);
  const opts = {
    height: '390',
    width: 'auto',
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <div>
      <div class="iq_wrapper">
        <header class="iq_header_1">
          <div class="container">
            <div class="iq_logo">
              <a href="/">
                <img src="images/logo/esisa.png" alt="Iqoniq Themes" />
              </a>
            </div>
          </div>
          <div class="navigation-wrapper pull-left">
            <Navbar />
            <NavbarMob />
          </div>
        </header>
        <Routes>
          <Route path="/" element={<Slider />} />{" "}
        </Routes>

        <div class="iq_content_wrap" style={{ backgroundColor: "white" }}>
          <section class="coures_single_page">
            <div class="container" style={{ width: "auto", zoom: "100%" }}>
              <Routes>
                <Route path="/" element={<EtudAdmis />} />
                <Route path="/Mot_president" element={<Mot_president />} />
                <Route
                  path="/Conferences_ESISA"
                  element={<Conferences_ESISA />}
                />
                <Route
                  path="/Conferences_etranger"
                  element={<Conferences_etranger />}
                />
                <Route path="/Etudier_ESISA" element={<Etudier_ESISA />} />
                <Route path="/Objectifs_ESISA" element={<Objectifs_ESISA />} />
                <Route
                  path="/Themes_recherche"
                  element={<Themes_recherche />}
                />
                <Route path="/Voix_ESISA" element={<Voix_ESISA />} />
                <Route path="/Workshop" element={<Workshop />} />

                <Route
                  path="/Systeme_enseignement"
                  element={<Systeme_enseignement />}
                />
                <Route
                  path="/Procedure_admission"
                  element={<Procedure_admission />}
                />
                <Route path="/Bac3" element={<Bac3 />} />
                <Route path="/Bac5ISI" element={<Bac5ISI />} />
                <Route path="/Bac5ISD" element={<Bac5ISD />} />
                <Route path="/Bac5IS2IA" element={<Bac5IS2IA />} />
                <Route path="/Bac5ISITN" element={<Bac5ISITN />} />
                <Route path="/Ccna" element={<Ccna />} />
                <Route
                  path="/ESISA_International"
                  element={<ESISA_International />}
                />
                <Route path="/Admis_Etranger" element={<Admis_Etranger />} />
                <Route path="/Nos_Partenaires" element={<Nos_Partenaires />} />

                <Route
                  path="/ESISAEntreprises"
                  element={<ESISAEntreprises />}
                />
                <Route path="/Stages" element={<Stages />} />
                <Route path="/Emplois" element={<Emplois />} />
                <Route
                  path="/SocietesPartenaires"
                  element={<SocietesPartenaires />}
                />

                <Route path="/Galerie/" element={<Galerie />}>
                  <Route path="/Galerie/" element={<Galimg12 />} />
                  <Route path="/Galerie/Galimg12" element={<Galimg12 />} />
                  <Route path="/Galerie/Galimg1" element={<Galimg1 />} />
                  <Route path="/Galerie/Galimg2" element={<Galimg2 />} />
                  <Route path="/Galerie/Galimg3" element={<Galimg3 />} />
                  <Route path="/Galerie/Galimg4" element={<Galimg4 />} />
                  <Route path="/Galerie/Galimg5" element={<Galimg5 />} />
                  <Route path="/Galerie/Galimg6" element={<Galimg6 />} />
                  <Route path="/Galerie/Galimg7" element={<Galimg7 />} />
                  <Route path="/Galerie/Galimg8" element={<Galimg8 />} />
                  <Route path="/Galerie/Galimg9" element={<Galimg9 />} />
                  <Route path="/Galerie/Galimg10" element={<Galimg10 />} />
                  <Route path="/Galerie/Galimg11" element={<Galimg11 />} />
                  <Route path="/Galerie/Galimg13" element={<Galimg13 />} />
                </Route>
                <Route path="/Presse" element={<Presse />} />

                <Route path="/PREINSCRIPTION" element={<Preinscription />} />
              </Routes>
            </div>
          </section>
        </div>

        <Routes>
          <Route path="/" element={<Accueil />} />
        </Routes>
        <Contactinfo />
           <Footer />
        <Footerstrip />
      </div>
    </div>
  );
}


export default App;
