import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NosPartenaires from "../components/NosPartenaires";
import Misc from "../Page/Admis/misc.js";
import Lille from "../Page/Admis/lille";
import Eil from "../Page/Admis/eil";
import Lyon from "../Page/Admis/lyon";
import Clermont from "../Page/Admis/clermont";
import Dijon from "../Page/Admis/dijon";
import Ensiie from "../Page/Admis/ensiie";
import Poly from "../Page/Admis/polytech";
import Paris8 from "../Page/Admis/paris8.js";
import Lorraine from "../Page/Admis/lorraine";
import Sorbonne from "../Page/Admis/sorbonne";
import Iscid from "../Page/Admis/mba";

import "./EtudAdmis.css";

export default function EtudAdmis() {
  const [sliderIndex, setSliderIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setSliderIndex(index),
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="course_single_heading">
          <div className="iq_heading_1 text-left">
            <h4>
              <span style={{ fontSize: "25px" }}>
                Etudiants Admis en France (Ecoles d’Ingénieurs et Universités
                Etatiques )
              </span>
            </h4>
          </div>
          <div className="course-rating">
            <Slider {...settings}>
              <div className="flex">
                <Ensiie />

                <Poly />
              </div>
              <div className="flex">
                <Lille />
                <Misc />
              </div>

              <div className="flex">
                <Lorraine />
                <Clermont />
              </div>
              <div className="flex">
                <Dijon />
                <Eil />{" "}
              </div>
              <div className="flex">
                <Lyon /> <Sorbonne />
              </div>
              <div className="flex">
                <Paris8 />
                <Iscid />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <NosPartenaires />
    </div>
  );
}
