import React from 'react';
import Rentree from '../../Page/Admis/rentree.js';
import "./ecolePartenaire.css";

export default function eil() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/cote1.png"  
                />
            </div>   
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                EILCO-Ecole d'Ingénieurs du Littoral Côte d'Opale <Rentree />
            </a>
            <br /> <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENABBOU Mohamed Amine - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Cycle d'Ingénieur Spécialité Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LAHOUIR Mehdi - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Cycle d'Ingénieur Spécialité Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL MOUDDEN Walid - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Cycle d'Ingénieur Spécialité Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ASSIOUS Oussama - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Cycle d'Ingénieur Spécialité Informatique</span>
            </p>
        </div>     
    )
}
