import React, { Component } from "react";
import Slider from "react-slick";

export default class PauseOnHover extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 100000,
      pauseOnHover: true,


      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
          }
        }
      ]
    };
    return (
	<section style={{backgroundColor:'white'}}>
                    <div class="container" style={{width:'auto'}}>
        <Slider {...settings}>
				            <img src="images/banner/clermonVer.jpg" style={{paddingLeft: '0px',paddingRight: '0px'}}/>
                    <img src="images/banner/Cergy-pontoise-petit.jpg"/>
                    <img src="images/banner/eil-petit.jpg"/>
                    <img src="images/banner/ensiie-petit.jpg"/>
                    <img src="images/banner/uge-petit.jpg"/>
		                 <img src="images/banner/isima-petit.jpg"/>
                    <img src="images/banner/lille.png"/>
                    <img src="images/banner/lis-petit.jpg"/>
                    <img src="images/banner/paris-13-petit.jpg"/>
                    <img src="images/banner/paris-8-petit.jpg"/>
                    <img src="images/banner/polytech-petit.jpg"/>
 		            <img src="images/banner/universite-de-lorraine-petit.jpg"/>
		            <img src="images/banner/ub-petit.jpg"/>
        </Slider>
          </div>
                    	</section>   
    );
  }
}