import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function lille() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/lille1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' }} 
                />
            </div>
            <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université de Lille <Rentree />
            </a>
            <br /><br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AGOUMI Salma - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENZAKOUR Omar - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                CHAFI Aya - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                WARDIGHI Wissal - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                FASSI FIHRI Mohamed (M1) - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                CHAHYD ZIAD (M1) - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL MOUSLIHE Mohammed (M1) - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Web Analyste</span>
            </p>
        </div>
    )
}
