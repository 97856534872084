import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function polytech() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/poly1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' }} 
                />
            </div>
            <br /> 
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Ecole Polytechnique Universitaire de Marseille <Rentree />
            </a>
            <br /> <br />
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                OUAFELLAOUI Imad - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                JAMAL Taha - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BOURICHA Anas - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BOURGUIBA Ihssane - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                KHARRATA Aya - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENNANI Yassine - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Industriel et Informatique</span>
            </p>
        </div>
    )
}
