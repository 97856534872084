import React from "react";
import Slider from "react-slick";

const testimonials = [
  {
    name: "Zarouk Amine",
    title: "Fondateur | PDG | Entrepreneur Tech | Industrie 4.0, AgriTech, GreenTech",
    image: "images/temoi/amine.png",
    text: "Après avoir obtenu mon diplôme de l'ESISA, j'ai pu bénéficier d'une équivalence systématique pour continuer des études à l'université Paris12 et suivre ensuite une formation d'Ingénieur d'affaires..."
  },
  {
    name: "Abdoulaye Sow",
    title: "Coach Agile, Capgemini Houston USA",
    image: "images/temoi/sow.png",
    text: "ESISA c'est pas juste une école, ils te mettent en place un énorme fondement et ensuite tu peux réussir ta vie..."
  },
  {
    name: "Mahmoud RAZOUKI",
    title: "Directeur Adjoint Hardis Group Paris",
    image: "images/temoi/razouki.png",
    text: "On voit la qualité des gens qu'on reçoit quand le diplôme est de l'ESISA. Les compétences techniques, on les trouve partout, mais la posture d'ingénieur, on ne la trouve pas partout."
  },
  {
    name: "El Moudni Safia",
    title: "Product Owner, Transatel Paris",
    image: "images/temoi/safia.png",
    text: "L'ESISA est pour moi le meilleur choix que j'ai fait car elle m'a donné l'opportunité de vivre deux expériences : Effectuer un stage à LIFC à Besançon, où j'ai..."
  },
  {
    name: "Mohamed Rahouti",
    title: "Enseignant-chercheur en Blockchain et Sciences de l'Information",
    image: "images/temoi/rahouti.png",
    text: "C'est toujours un plaisir de revenir à l'ESISA, je me sens chez moi..."
  },
  {
    name: "Berrada Karim",
    title: "Directeur Général Adjoint du Groupe ECA Maroc",
    image: "images/temoi/karim.png",
    text: "Lauréat de la troisième promotion, je retiendrais toujours l'ESISA comme école de qualité, de partage et de progrès. En suivant un cursus long de quatre années, c'est l'école de la vie qui nous est enseigné..."
  },
  {
    name: "Reda Bouchalkha",
    title: "Senior Program Manager à IT New Approach",
    image: "images/temoi/Bouchalkha.png",
    text: "Lauréat de ESISA en 2007, j'ai eu la chance d'intégrer l'école Polytech Marseille, une des écoles d'ingénieurs de renommé en France..."
  },
  {
    name: "Squalli Mamoun",
    title: "Chef de projet IT & Supply Chain",
    image: "images/temoi/mamoun.png",
    text: "En 2001, j'ai intégré l'ESISA pour une formation de quatre ans. Ces quatre années m'ont permis d'acquérir de bonnes connaissances en mathématiques et Informatique, ce qui m'a fortement aidé lors de mon intégration à Polytech Marseille..."
  },
  {
    name: "Yahyaoui Ghita",
    title: "Consultant senior chez l'UFF",
    image: "images/temoi/ghita.png",
    text: "La richesse et la diversité de ses enseignements sont deux atouts majeurs qui font de l'ESISA une porte d'ouverture à la fois sur le monde professionnel..."
  },
  {
    name: "Fahem Naoufal",
    title: "IT Project Manager à BNP Paribas, Paris",
    image: "images/temoi/naoufal.png",
    text: "Choisir l'ESISA, c'est intégrer une Ecole d'Ingénieurs atypique où l'on vous apprend, dès vos premiers cours, à vous prendre en charge, à vous affirmer, et surtout à remettre perpétuellement en..."
  },
  {
    name: "Benkhayat Youness",
    title: "Ingénieur Consultant au sein de SQLI France",
    image: "images/temoi/younes.png",
    text: "La formation assurée par l'ESISA est assez impressionnante. Tout en fournissant les bases théoriques nécessaires, elle privilégie l'aspect..."
  },
  {
    name: "Niangado Ibrahim",
    title: "Ingénieur Consultant chez VAVIS, France",
    image: "images/temoi/ibrahim.png",
    text: "Lauréat de la 4ème promo, je suis l'actualité de l'ESISA à travers notamment son site internet avec une émotion particulière. C'est avec un réel plaisir que je témoigne ma pleine gratitude..."
  }
];

const PauseOnHover = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section style={{ backgroundColor: "white" }}>
      <div className="container" style={{ width: "auto", zoom: "100%" }}>
        <div className="iq_heading_1 text-center">
          <h4>
            ESISA LAUREATES <span>Témoignages</span>
          </h4>
        </div>
        <Slider {...settings}>
          {testimonials.map(({ name, title, image, text }, index) => (
            <div className="testimonial" key={index}>
              <p>{text}</p>
              <div className="coures_instractor_thumb">
                <figure>
                  <img
                    style={{ borderRadius: "50%" }}
                    src={image}
                    alt={name}
                  />
                </figure>
                <div className="thumb_capstion">
                  <h5>
                    <a href="#">{name}</a>
                  </h5>
                  <p>{title}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default PauseOnHover;
