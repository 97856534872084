import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg13() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">

 <Gallery>
 <Item original="../images/remise22/fete1.jpeg"
          thumbnail="../images/remise22/fete1.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete1.jpeg" />
          
          )}
        </Item>
 <Item original="../images/remise22/fete2.jpeg"
          thumbnail="../images/remise22/fete2.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete2.jpeg" />
          
          )}
        </Item>
 <Item original="../images/remise22/fete3.jpeg"
          thumbnail="../images/remise22/fete3.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete3.jpeg" />
          
          )}
        </Item>
        
        <Item original="../images/remise22/fete4.jpeg"
          thumbnail="../images/remise22/fete4.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete4.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete5.jpeg"
          thumbnail="../images/remise22/fete5.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete5.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete6.jpeg"
          thumbnail="../images/remise22/fete6.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete6.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete7.jpeg"
          thumbnail="../images/remise22/fete7.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete7.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete8.jpeg"
          thumbnail="../images/remise22/fete8.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete8.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete9.jpeg"
          thumbnail="../images/remise22/fete9.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete9.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete10.jpeg"
          thumbnail="../images/remise22/fete10.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete10.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete11.jpeg"
          thumbnail="../images/remise22/fete11.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete11.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete12.jpeg"
          thumbnail="../images/remise22/fete12.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete12.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete13.jpeg"
          thumbnail="../images/remise22/fete13.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete13.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete14.jpeg"
          thumbnail="../images/remise22/fete14.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete14.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete15.jpeg"
          thumbnail="../images/remise22/fete15.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete15.jpeg" />
          
          )}
        </Item>


        <Item original="../images/remise22/fete16.jpeg"
          thumbnail="../images/remise22/fete16.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete16.jpeg" />
          
          )}
        </Item>

        <Item original="../images/remise22/fete17.jpeg"
          thumbnail="../images/remise22/fete17.jpeg"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/remise22/fete17.jpeg" />
          
          )}
        </Item>


      </Gallery>


    
    </div>
  )
}
