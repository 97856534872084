import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'


export default function Galimg4() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">
      <Gallery>
      <Item original="../images/images5/activite/22.jpg"
        thumbnail="../images/images5/activite/22.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/22.jpg" />
        
        )}
      </Item>

      <Item original="../images/images5/activite/33.jpg"
        thumbnail="../images/images5/activite/33.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/33.jpg" />
        
        )}
      </Item>

      <Item original="../images/images5/activite/44.jpg"
        thumbnail="../images/images5/activite/44.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/44.jpg" />
        
        )}
      </Item>

      <Item original="../images/images5/activite/55.jpg"
        thumbnail="../images/images5/activite/55.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/55.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/66.jpg"
        thumbnail="../images/images5/activite/66.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/66.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/77.jpg"
        thumbnail="../images/images5/activite/77.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/77.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/88.jpg"
        thumbnail="../images/images5/activite/88.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/88.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/99.jpg"
        thumbnail="../images/images5/activite/99.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/99.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/110.jpg"
        thumbnail="../images/images5/activite/110.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/110.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/120.jpg"
        thumbnail="../images/images5/activite/120.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/120.jpg" />
        
        )}
      </Item>

      <Item original="../images/images5/activite/130.jpg"
        thumbnail="../images/images5/activite/130.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/130.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/140.jpg"
        thumbnail="../images/images5/activite/140.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/140.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/150.jpg"
        thumbnail="../images/images5/activite/150.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/150.jpg" />
        
        )}
      </Item>

      <Item original="../images/images5/activite/160.jpg"
        thumbnail="../images/images5/activite/160.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/160.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/170.jpg"
        thumbnail="../images/images5/activite/170.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/170.jpg" />
        
        )}
      </Item>


      <Item original="../images/images5/activite/180.jpg"
        thumbnail="../images/images5/activite/180.jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/activite/180.jpg" />
        
        )}
      </Item>


      

          </Gallery>









    </div>
  )
}
