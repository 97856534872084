import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg12() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">

 <Gallery>
 <Item original="../images/fete22/fete18.png"
          thumbnail="../images/fete22/fete18.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete18.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete15.png"
          thumbnail="../images/fete22/fete15.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete15.png" />
          
          )}
        </Item>
       
 <Item original="../images/fete22/fete1.png"
          thumbnail="../images/fete22/fete1.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete1.png" />
          
          )}
        </Item>
        
        <Item original="../images/fete22/fete2.png"
          thumbnail="../images/fete22/fete2.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete2.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete3.png"
          thumbnail="../images/fete22/fete3.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete3.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete4.png"
          thumbnail="../images/fete22/fete4.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete4.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete6.png"
          thumbnail="../images/fete22/fete6.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete6.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete7.png"
          thumbnail="../images/fete22/fete7.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete7.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete8.png"
          thumbnail="../images/fete22/fete8.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete8.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete9.png"
          thumbnail="../images/fete22/fete9.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete9.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete10.png"
          thumbnail="../images/fete22/fete10.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete10.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete11.png"
          thumbnail="../images/fete22/fete11.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete11.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete12.png"
          thumbnail="../images/fete22/fete12.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete12.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete13.png"
          thumbnail="../images/fete22/fete13.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete13.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete14.png"
          thumbnail="../images/fete22/fete14.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete14.png" />
          
          )}
        </Item>



        <Item original="../images/fete22/fete16.png"
          thumbnail="../images/fete22/fete16.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete16.png" />
          
          )}
        </Item>

        <Item original="../images/fete22/fete17.png"
          thumbnail="../images/fete22/fete17.png"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
           
          <img ref={ref} onClick={open} width="464px" height="350px" src="../images/fete22/fete17.png" />
          
          )}
        </Item>


      </Gallery>


    
    </div>
  )
}
