import React from 'react'
import Actualites from '../components/Actualites';
import Videos from '../components/Videos';
import Galerie from '../components/Galerie';
import Temoignage from '../components/Temoignage';
import Banner from '../components/Banner';
import Iframe from '../components/iframe';
export default function Accueil() {
  return (
      <div>
                                 <Actualites />
                                  <Videos />  
                                  {/* <Galerie /> */}
                                  <Temoignage />
                                  <Banner />
                                  <Iframe />
    </div>
  )
}
