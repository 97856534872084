import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function clermont() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/uca1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' }} 
                />
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université Clermont Auvergne <Rentree />
            </a>
            <br/> 
            <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BELGHALI Mohammed - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Logiciel et Intégration d'Applications</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENLOUALI Ouiam - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Systèmes d'Information et Aide à la décision</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENMOUMEN Mohammed (M1) - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Informatique ST Santé</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                CHAIBOUB Chorouq (M1) - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Informatique ST Santé</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                HARRATI Zaid (M1) - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Informatique ST Santé</span>
            </p>
        </div>
    )
}
