import React from 'react';
import { useNavigate } from 'react-router-dom';
import NosPartenaires from '../../components/NosPartenaires';
import { Button } from 'react-bootstrap';
import { FaPaperPlane, FaDownload } from 'react-icons/fa';

export default function Bac5IS2IA() {
  const navigate = useNavigate();

  const handleCandidaterClick = () => {
    navigate('/PREINSCRIPTION');
  };

  const handleDownloadClick = () => {
    window.open('images/final/IS2IA.pdf', '_blank');
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="course_single_heading text-center">
          <div className="iq_heading_1">
            <h4 style={{ fontSize: '25px' }}>
              INGÉNIERIE DES SYSTÈMES D'INFORMATION APPLIQUÉE À L'INTELLIGENCE ARTIFICIELLE (IS2IA)
            </h4>
          </div>
          <div className="course-rating" style={{ height: '100%', overflow: 'hidden' }}>
            <img 
              src="images/final/IS2IA.png" 
              alt="IS2IA Image"
              style={{ width: '100%', height: 'auto' }} 
            />
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <Button 
              variant="primary" 
              className="d-flex align-items-center mr-2"
              onClick={handleCandidaterClick}
            >
              <FaPaperPlane className="mr-2" />
              Candidater
            </Button>
           
          </div>
        </div>
      </div>
      <Button 
              variant="secondary" 
              className="d-flex align-items-center"
              onClick={handleDownloadClick}
            >
              <FaDownload className="mr-2" />
              Télécharger la brochure
            </Button>
    </div>
  );
}
