import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg2() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">
      <Gallery>
      <Item original="../images/images5/1(1).jpg"
        thumbnail="../images/images5/1(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/1(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/2(1).jpg"
        thumbnail="../images/images5/2(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/2(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/3(1).jpg"
        thumbnail="../images/images5/3(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/3(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/4(1).jpg"
        thumbnail="../images/images5/4(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/4(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/5(1).jpg"
        thumbnail="../images/images5/5(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/5(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/6(1).jpg"
        thumbnail="../images/images5/6(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/6(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/7(1).jpg"
        thumbnail="../images/images5/7(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/7(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/8(1).jpg"
        thumbnail="../images/images5/8(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/8(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/9(1).jpg"
        thumbnail="../images/images5/9(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/9(1).jpg" />
       
        )}
      </Item>
      <Item original="../images/images5/10(1).jpg"
        thumbnail="../images/images5/10(1).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/10(1).jpg" />
       
        )}
      </Item>

      </Gallery>
     
</div>
  )
}
