import React from 'react'

export default function Contactinfo() {
  return (
    <div class="iq_contact_info">
                <div class="container">
                    <ul >
                    <li >
                            <i class="fa fa-map-marker"></i>
                            <div class="iq_info_wrap">
                                <h5>Adresse</h5>
                                <p>29 bis Av Ibn Khatib Route d'Immouzzer.<span>Fès-Maroc</span></p>
                            </div>
                        </li>
                        <li >
                            <i class="fa fa-phone"></i>
                            <div class="iq_info_wrap">
                                <h5>Téléphone </h5>
                                <span>+ 212 (0)5 35 65 70 95</span>
								<h5>FAX</h5>
                                <span>+ 212 (0)5 35 65 97 90</span>
                            </div>
                        </li>
                        <li >
                            <i class="fa fa-envelope"></i>
                            <div class="iq_info_wrap">
                                <h5>Email</h5>
                                <a href="mailto:info@esisa.ac.ma">info@esisa.ac.ma </a>
                            </div>
                        </li>
                    </ul>
                </div> 
            </div>

  )
}
