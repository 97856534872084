import React from "react";
import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <div>
      <div class="navigation pull-left">
        <ul>
          <li class="active">
            <Link to="/">ACCUEIL</Link>
          </li>

          <li>
            <a>A PROPOS DE L'ESISA</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/Mot_president">
                  Mot du président
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Etudier_ESISA">
                  Etudier à l'ESISA
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Objectifs_ESISA">
                  Objectifs de l'ESISA
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Voix_ESISA">
                  La voix des anciens
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Conferences_ESISA">
                  Conférences à l'ESISA
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Conferences_etranger">
                  Conférences à l'étranger
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Workshop">
                  Workshop
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Themes_recherche">
                  Thèmes de recherche
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>ENSEIGNEMENT</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/Systeme_enseignement">
                  Système d'enseignement
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Procedure_admission">
                  Procédure d'admission
                </Link>
              </li>
              <li>
                <a
                  class="navcolor"
                  href="/../../pdf/Reglement_interieur.pdf"
                  target="_blank"
                >
                  Règlement intérieur
                </a>
              </li>
              <li>
                <Link class="navcolor" to="/Bac3">
                  Bac + 3
                </Link>
              </li>
              <li>
                <a class="navcolor">Bac + 5</a>
                <ul class="children">
                  <li>
                    <Link
                      class="navcolor"
                      style={{ color: "blue" }}
                      to="/Bac5ISI"
                    >
                      ISI
                    </Link>
                  </li>
                  <li>
                    <Link class="navcolor" to="/Bac5ISD">
                      ISD
                    </Link>
                  </li>
                  <li>
                    <Link class="navcolor" to="/Bac5IS2IA">
                      IS2IA
                    </Link>
                  </li>
                  <li>
                    <Link class="navcolor" to="/Bac5ISITN">
                      ISITN
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link class="navcolor" to="/Ccna">
                  Formation CCNA(CISCO)
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>L'INTERNATIONAL</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/ESISA_International">
                  ESISA et L'international
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Admis_Etranger">
                  Admis à L'étranger
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Nos_Partenaires">
                  Nos partenaires
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>ENTREPRISES ET STAGES</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/ESISAEntreprises">
                  ESISA-Entreprises
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Stages">
                  Stages
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Emplois">
                  Emplois
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/SocietesPartenaires">
                  Sociétés partenaires
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>AUTRES</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/Presse">
                  Presse
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Galerie">
                  Galerie
                </Link>
              </li>
              {/*<li><Link class="navcolor"  to="/Contactez-nous">Contactez-nous</Link></li>*/}
            </ul>
          </li>
          {/* <li><a href="/PRE-INSCRIPTION.php">PRE-INSCRIPTION</a></li> */}
          <li>
            <Link to="/PREINSCRIPTION">PRE-INSCRIPTION</Link>
          </li>

          <li>
            <a style={{ textTransform: "uppercase" }}>Espaces étudiant</a>{" "}
            <ul class="children">
              <li>
                <a class="navcolor" href="https://classroom.google.com/h">
                  Cours en Ligne
                </a>
              </li>
              <li>
                <a class="navcolor" href="https://esisa.org.ma/login/index.php">
                  Moodle{" "}
                </a>
              </li>
              <li>
                <a
                  class="navcolor"
                  href="https://esisa-portail-intranet.vercel.app/"
                >
                  Intranet
                </a>
              </li>
            </ul>
          </li>

          <li class="active">
            <Link to="https://orbite.ma/esisa/">VISITE VIRTUELLE</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
