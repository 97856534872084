import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './Mot_president.css'; // Assurez-vous d'importer le fichier CSS

export default function Mot_president() {
  return (

	<>

<div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
            Étudier à l'ESISA
          </span>
        </h4>
      </div>

	  <div className="president-container">
      <div className="president-content">
        <div className="president-details">
          <img
            src="images/images/president.png"
            alt="Président"
            className="president-image"
          />
          <div className="president-text">
            <p>L'ESISA a su acquérir, depuis sa création en 1999, une très grande renommée dans le domaine de l'Ingénierie Informatique grâce à la formation originale qu'elle offre et à son projet innovant.</p>
            <p>L'école dispose d'un corps enseignant hautement qualifié et des moyens pédagogiques de grande qualité...</p>
            <p>Ainsi, nous permettons à nos élèves et à nos futurs ingénieurs de s'adapter aux défis nouveaux qui sont dus aux développements extraordinaires des technologies de l'Information et de la communication dans un contexte international...</p>
          </div>
        </div>
        <h5 className="president-signature">Pr. Khalid MEKOUAR - Président Directeur Pédagogique de l'ESISA</h5>
        <ul className="president-list">
          <li><a href="#" className="president-link">Fondateur Docteur ingénieur en informatique.</a></li>
          <li><a href="#" className="president-link">Ex-Maître assistant à l'Université Nice-Sophia Antipolis (France).</a></li>
          <li><a href="#" className="president-link">Ex-PDG de la société MKSoft (France) spécialisée dans le génie logiciel.</a></li>
          <li><a href="#" className="president-link">Prix Sadi Carnot ATEE 1991...</a></li>
          <li><a href="#" className="president-link">Concepteur et Réalisateur de huit logiciels de renommée internationale.</a></li>
        </ul>
        <h5 className="president-email"><a href="mailto:k.mekouar@esisa.ac.ma">Email : k.mekouar@esisa.ac.ma</a></h5>
      </div>
    </div>
	</>
  
  );
}
