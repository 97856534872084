import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function lyon() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/lyon1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' }} 
                />  
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université Lumière Lyon 2 <Rentree />
            </a>
            <br/> <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AMIROUCH Meriem - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ISMAILI Riad - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                RHALEM Ahmed - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                SALIH Achraf - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                DIONI Al Hassane - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>OPSIE</span>
            </p>
        </div>
    )
}
