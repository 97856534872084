import React from "react";
import NosPartenaires from "../../components/NosPartenaires";

const AnciensEtudiants = [

	{
	  nom: "EL MOUDNI Safia",
	  titre: "Product OwnerProduct Owner, Transatel Paris",
	  image: "images/temoi/safia.png",
	  description: `L'ESISA est pour moi le meilleur choix que j'ai fait car elle m'a donné l'opportunité de vivre deux expériences : Effectuer un stage à LIFC à Besançon, où j'ai intégré une équipe d'enseignants chercheurs qui m'ont proposé par la suite de passer une thèse au sein du laboratoire. Et puisque j'avais des orientations plutôt professionnelles, et grâce à l'école ESISA, j'ai pu intégrer en 4ème année l'école Polytech'Marseille - GII (Génie Industriel et Informatique) où j'ai préparé le diplôme d'ingénieur d'état. Aujourd'hui, je suis en CDI chez Capgemini Telecom Média Défense à Paris en qualité d'Ingénieur d'études.`
	},
	{
	  nom: "ZAROUK Amine",
	  titre: "Fondateur | PDG | Entrepreneur Tech | Industrie 4.0, AgriTech, GreenTech",
	  image: "images/temoi/amine.png",
	  description: `Après avoir obtenu mon diplôme de l'ESISA, j'ai pu bénéficier d'une équivalence systématique pour continuer des études à l'université Paris12 et suivre ensuite une formation d'Ingénieur d'affaires grands comptes à la CCIP. Aujourd'hui, grâce à ce cursus polyvalent et riche en rencontres, je me retrouve à la tête de G-FIT Maroc, la première SSII française qui s'installe dans la région. Je ne regrette aucune des décisions que j'ai prises au cours de mon cursus académique, encore moins celle d'avoir choisi l'ESISA.`
	},
	{
	  nom: "BERRADA Karim",
	  titre: "Directeur Général Adjoint du Groupe ECA Maroc",
	  image: "images/temoi/karim.png",
	  description: `Lauréat de la troisième promotion, je retiendrais toujours l'ESISA comme école de qualité, de partage et de progrès. En suivant un cursus long de quatre années, c'est l'école de la vie qui nous est enseigné; Un directeur de renom, une équipe pédagogique de force et une infrastructure de très haut niveau qui nous ont permis aujourd'hui de gravir les plus hauts échelons dans le monde professionnel et dans les meilleurs conditions possible. L'ESISA pour moi est une source d'inspiration et surtout une des clés de ma réussite.`
	},
	{
	  nom: "Reda BOUCHALKHA",
	  titre: "Senior Program Manager à IT New Approach",
	  image: "images/temoi/Bouchalkha.png",
	  description: `Lauréat de ESISA en 2007, j'ai eu la chance d'intégrer l'école Polytech Marseille, une des écoles d'ingénieurs de renommé en France. Les enseignements techniques et théoriques disposés au sein de ESISA, par un corps professoral de qualité, ainsi que l'accompagnement rigoureux assuré par son corps pédagogique, m'ont permis de réussir mon parcours d'ingénieur et d'arriver a chaque fois parmi les premiers de ma promotion. Aujourd'hui j'occupe le poste de Consultant Fonctionnel ERP au sein du groupe Capgemini, à Paris et j'interviens chez de grands comptes du CAC40.`
	},
	{
	  nom: "BELKHAYAT ZOUGGARI Idriss",
	  titre: "Ingénieur Expert Technique chez SQLI France",
	  image: "images/temoi/driss.png",
	  description: `Actuellement Ingénieur expert technique chez SQLI, le leader français des sociétés de services spécialisées dans les N.T.I.C, je suis responsable d'une équipe de 5 personnes dans un projet de plusieurs milliers de jour/homme, pour le compte d'une grande multinationale suisse. ESISA m'a donné le savoir, la motivation et la confiance pour me lancer dans une belle aventure qui est l'informatique, et pouvoir réussir mes missions au sein de l'entreprise.`
	},
	{
	  nom: "BENKHALED Mohamed",
	  titre: "Ingénieur d'Etude chez Capgemini Telecom Media & Defense à Paris",
	  image: "images/temoi/benkhaled.png",
	  description: `J'ai intégré l'ESISA en 2002 pour mon intérêt à l'informatique et aux sciences de l'ingénieur ainsi que pour la réputation qu'elle a pu acquérir 4 ans après sa création. Choisir cette école était pour moi un choix stratégique, à court terme : avoir un BAC + 4 en informatique et intégrer le marché de travail, et à moyen/long terme : poursuivre mes études en France pour un Master, diplôme d'ingénieur ou un doctorat. Aujourd'hui je ne regrette pas mes choix, pour la simple raison c'est que je pourrai intervenir dans n'importe quel domaine (Qualité, Industrie, Automobile, électronique, énergie, Ingénierie Informatique, Nouvelles technologies…) selon le besoin sur le marché, à l'aide de mon profil assez polyvalent.`
	},
	{
	  nom: "SQUALLI Mamoun",
	  titre: "Doctorat au Laboratoire des Sciences de l'Information et des Systèmes Marseille & Chef de projet IT & Supply Chain",
	  image: "images/temoi/mamoun.png",
	  description: `Après un bac en 2001, j'ai intégré l'ESISA pour une formation de quatre ans. Ces quatre années m'ont permis d'acquérir de bonnes connaissances en systèmes d'information et de calcul. Ma volonté de poursuivre des études à un niveau supérieur m'a poussé à demander une inscription en France pour préparer un M2 "Recherche informatique" de Marseille à l'université PAUL CEZANNE, spécialité Info Système (Modélisation et Simulation). Après le master, j'ai intégré le laboratoire LSIS de Marseille pour préparer mon diplôme de doctorat en informatique, où je suis actuellement en 2ème année de thèse, ce qui me permet d'approfondir mes connaissances et bénéficier des opportunités offertes afin acquérir le maximum d'expériences.`
	},
	{
	  nom: "YAHYAOUI Ghita",
	  titre: "Consultant senior chez l'UFF - Banque conseil en gestion de patrimoine ",
	  image: "images/temoi/ghita.png",
	  description: `La richesse et la diversité de ses enseignements sont deux atouts majeurs qui font de l'ESISA une porte d'ouverture à la fois sur le monde professionnel et sur le monde de la recherche. En effet, son programme solide et diversifié permet à ses étudiants d'avoir des compétences pluridisciplinaires, en adéquation avec le monde du travail, et en même temps d'avoir des bases pour se lancer dans le monde de la recherche. ESISA est pour moi une famille avant d'être une école. J'ai beaucoup apprécié mes quatre années passées dans cette école pour son corps enseignant, son corps administratif et ses partenariats.`
	},
	{
	  nom: "FAHEM Naoufal",
	  titre: "IT Project Manager à BNP Paribas, Paris",
	  image: "images/temoi/naoufal.png",
	  description: `Choisir l'ESISA, c'est intégrer une Ecole d'Ingénieurs atypique où l'on vous apprend, dès vos premiers cours, à vous prendre en charge, à vous affirmer, et surtout à remettre perpétuellement en question vos acquis en vue de créer et concevoir les nouveaux systèmes d'informations du XXIe siècle. Aujourd'hui j'occupe le poste d'ingénieur consultant au sein du Gaselys.Tous les jours, je mets à profit ce que j'ai appris à l'ESISA : les travaux de groupe, les études de cas réels, la curiosité, la prise d'initiative et la force de proposition.`
	},
	{
	  nom: "BENKHAYAT Youness",
	  titre: "Ingénieur Consultant au sein de SQLI France",
	  image: "images/temoi/younes.png",
	  description: `La formation assurée par l'ESISA est assez impressionnante. Tout en fournissant les bases théoriques nécessaires, elle privilégie l'aspect technique. Ainsi, elle offre un programme qui combine entre le savoir-faire théorique nécessaire pour comprendre les concepts fondamentaux de l'informatique, et la mise en application de ces concepts par le biais de projets professionnels. Au niveau personnel, je suis un consultant au sein de SQLI, France, je trouve que l'ESISA a parfaitement répondu à mes attentes, en me permettant d'intégrer le marché du travail avec une bonne formation en informatique.`
	}
  ];
  
export default function Voix_ESISA() {
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="course_single_heading">
          <div className="iq_heading_1 text-left">
            <h4>
              <span style={{ fontSize: "25px" }}>La voix des anciens</span>
            </h4>
          </div>
          <div
            className="course-rating"
            style={{
              overflow: "scroll",
              height: "1000px",
              overflowX: "hidden",
            }}
          >
            <ul className="blog_commenting_area">
              {AnciensEtudiants.map((ancien, index) => (
                <li key={index}>
                  <div className="comment-thumb">
                    <figure>
                      <img src={ancien.photo} alt={ancien.nom} />
                    </figure>
                    <div className="comment_caption">
                      <div className="comment_caption-2">
                        <span>
                          <strong>{ancien.nom}</strong>
                          <small>{ancien.titre}</small>
                        </span>
                      </div>
                      <div className="comment-reply-2">
                        <p>{ancien.description}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <NosPartenaires />
    </div>
  );
}
