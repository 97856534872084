import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';
export default function Nos_Partenaires() {
  return (
    <div class="row">
    <div class="col-md-8">							  
    <div class="course_single_heading">				
        <div class="iq_heading_1 text-left">
            <h4><span style={{fontSize:'25px'}}>PARTENAIRES DE L'ESISA</span></h4>
        </div>
        <div class="course-rating" style={{overflow: 'scroll',height:'1000px',overflowX: 'hidden'}}>  
        <p></p>
									<div class="row">
							
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/poly1.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des"  style={{height: '480px'}}>
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://polytech.univ-amu.fr/">Ecole Polytechnique Universitaire de Marseille</a></h5>
                                        <p>Elle a pour objectif de préparer en trois ans des ingénieurs dont la formation est ciblée vers une filière professionnelle de l'industrie et l’ingénierie informatique.</p>
										<p>Les lauréats de l'ESISA peuvent intégrer l'Ecole Polytechnique en 2ème année Spécialités :</p>
										
										<ul class="categries-list"> 
										<li>
										<a style={{color:'#777777'}}>Génie Industriel et Informatique
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Informatique Réseaux et Multimédia
													</a>
										</li>
																		
										
										
										</ul>
                                    </div>
									<a class="iq_link_1" href="#">visitez</a>
                                  
                                </div>
                            </div>
           
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/ensiie1.jpg" alt="Image Here"/>
                                    </figure>
                                    <div class="iq_course_list_des"  style={{height: '480px'}}>
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span><span class="path30"></span><span class="path31"></span><span class="path32"></span><span class="path33"></span><span class="path34"></span><span class="path35"></span><span class="path36"></span><span class="path37"></span><span class="path38"></span>
                                            </span>
                                        </div>
                                        <h5><a href="#">École Nationale Supérieure d’Informatique pour l’Industrie et l’entreprise</a></h5>
                                        <p>Les lauréats de l'ESISA peuvent intégrer ENSIIE en 2ème année pour préparer un diplôme d'ingénieur d'état français. Spécialités : </p>
                                        <p><ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Analyse du risque, optimisation et mathématique de la décision
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Robotisation et réalité virtuelle
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Ingénierie financière
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Organisation de l’entreprise
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Sécurité et réseau
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Ingénierie logicielle
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Ingénierie des systèmes d’information
													</a>
										</li>
								
										</ul>
										</p>
                                    </div>
                                    <a class="iq_link_1" href="#">visitez</a>
                                </div>
                            </div>
                          
                            <div class="col-md-6 col-sm-6" >
                                <div class="iq_course_list" >
                                    <figure>
                                        <img src="images/images/isima.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des"  style={{height: '480px'}}>
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://polytech.univ-amu.fr/">Institut Supérieur d'Informatique, de Modélisation et de leurs Applications (ISIMA) Clermont-Ferrand</a></h5>
                                        <p>Les lauréats de l'ESISA peuvent intégrer ISIMA de Clermont-Ferrand en 2ème année pour préparer un diplôme d'ingénieur d'état français. Spécialités :</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Informatique des Systèmes Embarqués.

													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Génie Logiciel et Systèmes Informatiques
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Systèmes d'Information et Aide à la Décision.
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Calcul et Modélisation Scientifiques.
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Réseaux et Télécommunications.
													</a>
										</li>		
								
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://polytech.univ-amu.fr/">visitez</a>
                         
                                </div>
                            </div>
                    
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/lis1.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des"  style={{height: '480px'}}>
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.lis-lab.fr//">Laboratoire d'Informatique et Systèmes (LIS)</a></h5>
                                        <p>Laboratoire d'Informatique et Systèmes (LIS)</p>
										<p>Masters de Recherche :</p>
									<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Ingénierie des systèmes d'information

													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Génie Logiciel
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Système, Commande et Diagnostic
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Conception de Produits et de Systèmes de Production.
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Modélisation, Commande et Simulation
													</a>
										</li>		
																												<li>
										<a style={{color:'#777777'}}>Imagerie Numérique
													</a>
										</li>	
																														<li>
										<a style={{color:'#777777'}}>Systèmes d'Information et Connaissance
													</a>
										</li>	
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.lis-lab.fr//">visitez</a>
                               
                                </div>
                            </div>
              
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/ubp.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.uca.fr/">Université de Clermont Auvergne</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université Blaise Pascal de Clermont-Ferrand:</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Master SIPPE : Stratégie Internet et Pilotage de Projets d'Entreprises
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master IDL : Informatique Décisionnelle et Logicielle
													</a>
										</li>
																		
										
										
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.uca.fr/">visitez</a>
                                  
                                </div>
                            </div>
                         
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/eil.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.eilco-ulco.fr/">École d'Ingénieurs du Littorale Côte d’Opale (Calais)</a></h5>
                                        <p>Les étudiants de la 4ème année de l'ESISA peuvent intégrer l'EIL Côte d’Opale en:</p>
										<p>Les lauréats de l'ESISA peuvent intégrer l'Ecole Polytechnique en 2ème année Spécialités :</p>
										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>2ème année du cycle d'ingénieur spécialité INFORMATIQUE pour préparer un diplôme d'Ingénieur d'état français.
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>2ème année du Master International d'ingénierie des Systèmes Complexes (MISC) .
													</a>
										</li>
									
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.eilco-ulco.fr/">visitez</a>
                                  
                                </div>
                            </div>
                    
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/paris81.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des"style={{height: '400px'}}>
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-paris8.fr/">Université de Paris 8</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université de Paris 8:</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Master Technologies de l'hypermédia

													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master Création et édition numériques
													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master Gestion de l'information et du document

													</a>
										</li>																		
										
										
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-paris8.fr/">visitez</a>
                                </div>
                            </div>
                        
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/lorraineg.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des"style={{height: '400px'}}>
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-lorraine.fr/">Université de Lorraine</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université de Lorraine:</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Master Informatique Décisionnelle


													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master Modélisation Logiciel

													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master Sécurité des architectures web

													</a>
										</li>																		
																				<li>
										<a style={{color:'#777777'}}>Master Interaction Humain-Machine

													</a>
										</li>											
																				<li>
										<a style={{color:'#777777'}}>Master Génie informatique

													</a>
										</li>	
																				<li>
										<a style={{color:'#777777'}}>Sécurité des systèmes d’information

													</a>
										</li>	
																						
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-lorraine.fr/">visitez</a>
   
                                </div>
                            </div>
    
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/ub1.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.u-bourgogne.fr/">Université de Bourgogne - Dijon</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université de Bourgogne:</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Master Vision - Image et Intelligence artificielle - Multimédia



													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master Base de données et Intelligence artificielle


													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Master Recherche Image-Informatique-Ingénierie


													</a>
										</li>																		
																							
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.u-bourgogne.fr/">visitez</a>
                 
                                </div>
                            </div>

						

                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/upem1.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-gustave-eiffel.fr/">Université Gustave Eiffel</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université PARIS-EST MARNE-LA-VALLEE :

</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Informatique fondamentale



													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Sciences de l'image


													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Systèmes et services pour l'internet des objets


													</a>
										</li>																		
																													<li>
										<a style={{color:'#777777'}}>Logiciels


													</a>
										</li>														
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-gustave-eiffel.fr/">visitez</a>
                      
                                </div>
                            </div>
                
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/unvlille.png" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-lille.fr/">Université de lille</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'Université de lille:</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Méthodes Quantitatives et Modélisation pour l'entreprise
	


													</a>
										</li>
																				<li>
										<a style={{color:'#777777'}}>Web Analyste



													</a>
										</li>
																															
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-gustave-eiffel.fr/">visitez</a>
                    
                                </div>
                            </div>
                
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/sorbonne.png" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-paris13.fr/">Université sorbonne paris nord</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université sorbonne paris nord:</p>


										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Exploration informatique des Données et Décisionnel



													</a>
										</li>
																				<li>Programmation et logiciels sûrs
										<a style={{color:'#777777'}}>



													</a>
										</li>
																															
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-gustave-eiffel.fr/">visitez</a>
                            
                                </div>
                            </div>
                    
                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/lumiere.png" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-paris13.fr/">Université lumière lyon 2</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder à l’un des masters professionnels publics de l'université sorbonne paris nord:</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Organisation et protection des systèmes d'information en entreprise



													</a>
										</li>
																															
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-gustave-eiffel.fr/">visitez</a>
                       
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="iq_course_list">
                                    <figure>
                                        <img src="images/images/ulcp.jpg" alt="Image Here"/> 	
                                    </figure>
                                    <div class="iq_course_list_des">
                                        <div class="iq_course_icon">
                                            <span class="icon-debate">
                                                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span>
                                            </span>
                                        </div>
                                        <h5><a href="https://www.univ-littoral.fr/">Université de du Littoral Côte d'Opale</a></h5>
                                        <p>Ce partenariat permettra aux étudiants de la 4ème année de l'ESISA d'accéder au master informatique :</p>

										
										<ul class="categries-list">
										<li>
										<a style={{color:'#777777'}}>Parcours Ingénierie des Systèmes Informatiques Distribués.



													</a>
										</li>
																																		
																		
										</ul>
                                    </div>
									<a class="iq_link_1" href="https://www.univ-littoral.fr/">visitez</a>
                                   
                                </div>
                            </div>

						</div>
       </div>		
      </div>
      </div>
      <NosPartenaires />
                         
                              
                         </div>
              )
            }