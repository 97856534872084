import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function Sorbonne() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/paris131.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' }} 
                />
            </div>
            <br /> <br />
            <a style={{ color: '#4e95ac', fontSize: '18px' }}>
                Université Sorbonne Paris Nord (Rentrée 2024-2025) <Rentree />
            </a>
            <br /> <br />
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                GADDAR Ouail - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Exploration Informatique des Données et Décisionnel</span>
            </p>
        </div>
    )
}
