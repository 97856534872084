import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function misc() {
    return (
        <div className="divecole4 fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire2' 
                    src="images/partenaire/unvop.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' }} 
                />
            </div>
            <br />  
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                Université du Littoral Côte d'Opale <Rentree />
            </a>
            <br /> <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                RAJI Mohammed - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Master Ingénierie Des Systèmes Complexes</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                FARAJ Mohammed - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Master Ingénierie Des Systèmes Complexes</span>
            </p>
        </div>
    )
}
