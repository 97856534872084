import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg10() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">
      <Gallery>

    <Item original="../images/images5/remise/1.jpg"
      thumbnail="../images/images5/remise/1.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/1.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/2.jpg"
      thumbnail="../images/images5/remise/2.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/2.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/3.jpg"
      thumbnail="../images/images5/remise/3.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/3.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/4.jpg"
      thumbnail="../images/images5/remise/4.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/4.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/5.jpg"
      thumbnail="../images/images5/remise/5.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/5.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/29.jpg"
      thumbnail="../images/images5/remise/29.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/29.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/27.jpg"
      thumbnail="../images/images5/remise/27.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/27.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/8.jpg"
      thumbnail="../images/images5/remise/8.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/8.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/10.jpg"
      thumbnail="../images/images5/remise/10.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/10.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/11.jpg"
      thumbnail="../images/images5/remise/11.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/11.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/12.jpg"
      thumbnail="../images/images5/remise/12.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/12.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/13.jpg"
      thumbnail="../images/images5/remise/13.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/13.jpg" />
      
      )}
    </Item>

    <Item original="../images/images5/remise/15.jpg"
      thumbnail="../images/images5/remise/15.jpg"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise/15.jpg" />
      
      )}
    </Item>
</Gallery>

     </div>
  )
}
