import React from 'react';
import { useNavigate } from 'react-router-dom';
import NosPartenaires from '../../components/NosPartenaires';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { Button } from 'react-bootstrap';
import { FaPaperPlane } from 'react-icons/fa';

export default function Bac3() {
  const navigate = useNavigate();

  const handleCandidaterClick = () => {
    navigate('/PREINSCRIPTION');
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="course_single_heading text-center">
          
        
       

          <div class="iq_heading_1 text-left">
    <h4><span style={{fontSize:'25px', textAlign:"center"}}>                 Ingénierie Logiciel (IL)
</span></h4>
</div>  
          <div className="course-rating" style={{ height: '100%', overflow: 'hidden' }}>
            <Gallery>
              <Item 
                original="images/images4/IL.jpg"
                thumbnail="images/images4/IL.jpg"
                width="1920"
                height="1080"
              >
                {({ ref, open }) => (
                  <img 
                    ref={ref} 
                    onClick={open} 
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }} 
                    src="images/images4/IL.jpg" 
                    alt="Ingénierie Logiciel"
                  />
                )}
              </Item>
            </Gallery>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <Button 
              variant="primary" 
              className="d-flex align-items-center"
              onClick={handleCandidaterClick}
            >
              <FaPaperPlane className="mr-2" />
              Candidater
            </Button>
          </div>
        </div>
      </div>
      {/* <NosPartenaires /> */}
    </div>
  );
}
