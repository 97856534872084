import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';
import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Conferences_ESISA() {
  return (
	<div class="row">
        <div class="col-md-8">							  
								<div class="course_single_heading">				
		                            <div class="iq_heading_1 text-left">
		                                <h4><span style={{fontSize:'25px'}}>CONFÉRENCES A L'ESISA</span></h4>
		                            </div>
                                <div class="course-rating" style={{overflow: 'scroll',height:'1000px',overflowX: 'hidden'}}>  
                                <div class="about_courses">	
									
									<div class="about_courses_thumb">
									
			                            
			                           
				                        <div class="iq_tab_menu">
				                        	<ul id="tabs" data-tabs="tabs">
				                            	<li class="active"><a data-toggle="tab" href="#NDIDO_2017" style={{fontSize: '13px'}}>NDIDO 2017</a></li>
				                                <li><a data-toggle="tab" href="#Frontiere_Numeriques" style={{fontSize: '13px'}}>Frontière Numériques, 2014</a></li>
				                                <li><a data-toggle="tab" href="#Document_Numerique" style={{fontSize: '13px'}}>Document Numérique, 2014</a></li>
												<li><a data-toggle="tab" href="#Frontiere_Numeriques12" style={{fontSize: '13px'}}>Frontière Numériques, 2012</a></li>
				                                <li><a data-toggle="tab" href="#I3M" style={{fontSize: '13px'}}>I3M 2010</a></li>
				                                <li><a data-toggle="tab" href="#E-MEDISYS10" style={{fontSize: '13px'}}>E-MEDISYS 2010 - FÈS</a></li>
				                                <li><a data-toggle="tab" href="#E-MEDISYS08" style={{fontSize: '13px'}}>E-MEDISYS 2008 - SFAX</a></li>
				                                <li><a data-toggle="tab" href="#E-MEDISYS07" style={{fontSize: '13px'}}>E-MEDISYS 2007 - FÈS</a></li>												
				                            </ul>
				                        </div>
				                       
			                            <div id="my-tab-content" class="tab-content">
                            				<div class="tab-pane active" id="NDIDO_2017">
												<div class="about_courses_thumb_capstion">
											
						                            
												<h5 style={{color:'#1962e5'}}>Nouveaux Défis de l’Internet Des Objets. 18 et 19 MAI 2017, Fès MAROC</h5>

						                           
						                        
												
			<p>Suite à la journée nationale organisée le 29 Novembre 2016 à la Cite´ de l’Innovation, Nokia Paris-Saclay (https://ido2016.sciencesconf.org/), nous souhaitons poursuivre l’exploration des nouveaux défis que l’Internet des Objets (IdO) lors d’une conférence de deux jours qui aura lieu à Fès au Maroc dans les locaux de l’Ecole Supérieure d’Ingénierie en Sciences Appliquées (ESISA). Cette conférence interrogera les problématiques de l’IdO suivant trois axes complémentaires technique, usage, éthique. Les techniques utilisées pour produire les artefacts (objets physiques, infrastructures), les programmes (algorithmiques, logiciels) et les données (Big Data, linked data, metadata, ontologies) font l’objet de multiple innovations tant le domaine de l’IdO est riche et stimulant.

</p>
		<h5 style={{color:'#1962e5'}}>Présidents de la conférence :

</h5>
				
													<ul class="categries-list">
																										<li>
																											<a href="#">Mehdi Ammi, Univ. Paris-Sud / LIMSI-CNRS, France.
													</a>
																											</li>
																													<li>
																											<a href="#">Sabine Coquillart, INRIA-Grenoble Rhône-Alpes, France
													</a>
																											</li>
																													<li>
																											<a href="#">Khalid Mekouar, Président Directeur Pédagogique de l'ESISA, Maroc
													</a>
																											</li>
																													<li>
																											<a href="#">Samuel Szoniecky, Univ. Paris 8 / Laboratoire Paragraphe - CiTU, France
													</a>
																											</li>
																												<li>
													<div class="iq_heading_1 iq_heading_2 text-left">
													<img src="images/images2/1.jpg" alt="Image Here"/>
													</div>
																											</li>
													</ul>

													  <div class="main-gallery">
													  <Gallery>

<Item original="images/images2/1.jpg"
thumbnail="images/images2/1.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images2/1.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images2/2.jpg"
thumbnail="images/images2/2.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images2/2.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images2/3.jpg"
thumbnail="images/images2/3.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images2/3.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images2/5.jpg"
thumbnail="images/images2/5.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images2/5.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

</Gallery>
						    
						</div>
												</div>
											
												<div class="border-div"></div>
									
											</div>
											<div class="tab-pane" id="Frontiere_Numeriques">
											<div class="about_courses_thumb_capstion">
						                            <div class="iq_heading_1 iq_heading_2 text-left">
						                                <h5 style={{color:'#1962e5'}}>2ème Edition du Colloque International "Frontière Numériques", Fès MAROC

</h5>
						                            </div>
													<div class="iq_heading_1 iq_heading_2 text-left">
						                                <h5 style={{color:'#1962e5'}}>Mardi 18 et Mercredi 19 Novembre 2014</h5>
						                            </div>
													<p>Colloque organisé par :</p>
													<ul class="categries-list">
												    <li>
													<a href="#">Le Laboratoire Paragraphe EA 349 (Université de Cergy-Pontoise),</a>
													</li>
													<li>
													<a href="#">Le Laboratoire Arts de Images & Art Contemporain EA (Université Paris 8),</a>
													</li>
												    <li>
													<a href="#">L'Ecole Supérieure d'Ingénierie en Sciences Appliquées,</a>
													</li>
												    <li>
													<a href="#">Avec le soutien de l'Initiative d'excellence en formations innoventes creatic et du Laboratoire d'Excellence Labex Arts-H2H</a>
													</li>													
													</ul>
													 <div class="main-gallery">
<Gallery>
<Item original="images/images3/1.jpg"
thumbnail="images/images3/1.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/1.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images3/2.jpg"
thumbnail="images/images3/2.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/2.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images3/4.jpg"
thumbnail="images/images3/4.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/4.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images2/colloque.jpg"
thumbnail="images/images2/colloque.jpg"
width="450"
height="700"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images2/colloque.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images3/5.jpg"
thumbnail="images/images3/5.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/5.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images3/6.jpg"
thumbnail="images/images3/6.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/6.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images3/7.jpg"
thumbnail="images/images3/7.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/7.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images3/8.jpg"
thumbnail="images/images3/8.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/8.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

<Item original="images/images3/9.jpg"
thumbnail="images/images3/9.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images3/9.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
</Gallery>
						
						
						</div>
													
												</div>
											</div>
											<div class="tab-pane" id="Document_Numerique">
												<div class="about_courses_thumb_capstion">
											
						                           <div class="iq_heading_1 iq_heading_2 text-left">
						                                <h5 style={{color:'#1962e5'}}>Livre post-numérique : historique, mutations et perspectives

</h5>
						                            </div>
													<div class="iq_heading_1 iq_heading_2 text-left">
						                                <h5 style={{color:'#1962e5'}}>19-20 Novembre 2014 – Fès, Maroc</h5>
						                            </div>
						                    
													<p>Colloque organisé par :</p>
														<ul class="categries-list">
												    <li>
													<a href="#">CiTU – Paragraphe, Université Paris 8, Saint-Denis – France</a>
													</li>
																									    <li>
													<a href="#">ESISA, Fés – Maroc</a>
													</li>
																									    <li>
													<a href="#">Europia, Paris – France</a>
													</li>
																									    <li>
													<a href="#">GERiiCO, Université de Lille – France</a>
													</li>
																									    <li>
													<a href="#">IDEFI – Creatic, Université Paris 8, Saint-Denis – France</a>
													</li>
																									    <li>
													<a href="#">LEDEN – MSH Paris-Nord, Saint-Denis – France</a>
													</li>													
													</ul>
													<p>Aujourd’hui, il est d’usage de parler et de pratiquer la lecture et l’écriture sur des ouvrages dématérialisés, augmentés, enrichis, contextualisés, interactifs, hybrides, crossmédiatisés, etc.

</p>
												</div>
											</div>
												<div class="tab-pane" id="Frontiere_Numeriques12">
												<div class="about_courses_thumb_capstion">
										
						                            <div class="iq_heading_1 iq_heading_2 text-left">
						                                <img src="images/images3/colloque2012.jpg" alt="Image Here"/>
						                            </div>
						                     
													<p>L'ESISA a organisé en partenariat avec l'Université de Paris 8 et le Laboratoire Paragraphe le 1er et 2 novembre 2012 un colloque sous le thème : <b>"Frontières Numériques et Créations géoartistiques et réalités géopolitiques en Méditerranée : Nouvelles expériences de la mobilité.</b>

</p>

<div class="main-gallery">
<Gallery>                    
<Item original="images/images4/3.jpg"
thumbnail="images/images4/3.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/3.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/2.jpg"
thumbnail="images/images4/2.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/2.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/1.jpg"
thumbnail="images/images4/1.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/1.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/6.jpg"
thumbnail="images/images4/6.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/6.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/5.jpg"
thumbnail="images/images4/5.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/5.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/4.jpg"
thumbnail="images/images4/4.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/4.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/9.jpg"
thumbnail="images/images4/9.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/9.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/8.jpg"
thumbnail="images/images4/8.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/8.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/7.jpg"
thumbnail="images/images4/7.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/7.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10.jpg"
thumbnail="images/images4/10.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/11.jpg"
thumbnail="images/images4/11.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/11.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/12.jpg"
thumbnail="images/images4/12.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/12.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/13.jpg"
thumbnail="images/images4/13.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/13.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

</Gallery>

                       							
						</div>
														
												</div>
											</div>
																						<div class="tab-pane" id="I3M">
												<div class="about_courses_thumb_capstion">
											
						                            <div class="iq_heading_1 iq_heading_2 text-left">
														<img src="images/images4/esisa1.png" alt="Image Here" class="img200"/>													
														<img src="images/images4/emss.png" alt="Image Here" class="img200"/>
						                                <img src="images/images4/hms.png" alt="Image Here" class="img200"/>
						                                <img src="images/images4/imaaca.png" alt="Image Here" class="img200"/>														
						                                <img src="images/images4/mas.png" alt="Image Here" class="img200"/>														
													
														
						                            </div>
						                    
													<p>I3M implique plusieurs conférences co-implantées sur le même site. En 2010 ESISA a co-organisé cet évènement scientifique qui favorise la discussion entre tous les simulationnistes, qui sont activement à jouer des rôles différents dans le modeling et la simulation, afin de transformer les intuitions et les attentes dans la réalité

</p>
													<p>L'initiative I3M est le principal outil pour améliorer la valeur des compétences et l'expertise afin de promouvoir efficacement les aspects innovants et les contributions scientifiques.

</p>
											<div class="main-gallery">
                  
<Gallery>
<Item original="images/images4/1-i3m.jpg"
thumbnail="images/images4/1-i3m.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/1-i3m.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/2-i3m.jpg"
thumbnail="images/images4/2-i3m.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/2-i3m.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/3-i3m.jpg"
thumbnail="images/images4/3-i3m.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/3-i3m.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/4-i3m.jpg"
thumbnail="images/images4/4-i3m.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/4-i3m.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/5-i3m.jpg"
thumbnail="images/images4/5-i3m.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/5-i3m.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/6-i3m.jpg"
thumbnail="images/images4/6-i3m.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/6-i3m.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
</Gallery>
                        
						</div>						
												</div>
											</div>
																						<div class="tab-pane" id="E-MEDISYS10">
												<div class="about_courses_thumb_capstion">
													

													<p>Suite au succès de la 1ère et 2ème conférence Emedisys 07/08 organisées par l'ESISA une troisième sera organisée à Fès le 12-14 Mai 2010.</p>
													<p>La conférence s'est tenue à la Faculté de Médecine et de Pharmacie de Fès

</p>
													<p><b>Adresse:</b> Route de Sefrou Outre le nouveau stade

</p>
													<p>E-Medisys est une nouvelle conférence internationale , très novateur , dans les rubriques de la télémédecine. Cette conférence est née de la collaboration de trois équipes de recherche réparties entre Sfax ( Tunisie ) , Besançon ( France ) et Fès ( Maroc ) .

</p>
													<p>Les actes du colloque ont été distribués aux participants sous forme imprimée .

</p>
  <div class="main-gallery">
<Gallery>  
		<Item original="images/images4/10-2.jpg"
thumbnail="images/images4/10-2.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-2.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-1.jpg"
thumbnail="images/images4/10-1.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-1.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/med.jpg"
thumbnail="images/images4/med.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/med.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-5.jpg"
thumbnail="images/images4/10-5.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-5.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-4.jpg"
thumbnail="images/images4/10-4.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-4.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-3.jpg"
thumbnail="images/images4/10-3.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-3.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-6.jpg"
thumbnail="images/images4/10-6.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-6.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-8.jpg"
thumbnail="images/images4/10-8.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-8.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>
<Item original="images/images4/10-7.jpg"
thumbnail="images/images4/10-7.jpg"
width="1024"
height="768"
>
							{({ ref, open }) => (
<div class="col-md-4 col-sm-6">
<div class="thumb">
				<figure>
<img ref={ref} onClick={open} width="365" height="243" src="images/images4/10-7.jpg" />
				</figure>
					</div>
				</div>

			)}
</Item>

</Gallery>                
                        
						</div>
												</div>
											</div>
																						<div class="tab-pane" id="E-MEDISYS08">
												<div class="about_courses_thumb_capstion">
						
						                            <div class="iq_heading_1 iq_heading_2 text-left">
						                               <img src="images/images4/stt4.gif" alt="Image Here"/>
						                            </div>
						                         
													<p>Conference Chairman</p>
													<p><b>Mohamed Salim BOUHLEL</b></p>
													<p>Professor, University of Sfax, E-Medisys, Tunisia</p>
													<p><b>Jean-christophe LAPAYRE</b></p>
													<p>Professor in the university of Franche-Comté, LIFC, France</p>
													<p><b>Khalid MEKOUAR</b></p>
													<p>President of ESISA Fès, Maroc</p>
													<p>Scientific Committee Chairman</p>
													<p><b>Mohamed ESSAAIDI</b></p>
													<p>IEEE Morocco Section Chair</p>
													<p><b>Jamel DAOUD</b></p>
												    <p>Professor in the Sfax Faculty of Medicine, Tunisia</p>






													
												</div>
											</div>
																						<div class="tab-pane" id="E-MEDISYS07">
												<div class="about_courses_thumb_capstion">
											
						                            <div class="iq_heading_1 iq_heading_2 text-left">
						                               <img src="images/images4/emedisys.jpg" alt="Image Here"/>
						                            </div>
						                         
													<p>e-Medisys est un e nouvelle conférence internationale, très novatrice, sur le thème de la télémédecine. Cette conférence est née de la collaboration de trois équipes de recherche réparties entre l’Université de Sfax-Tunis, l’Universités de Franche Comté-Besançon-France et l’Ecole Supérieure d’Ingénierie en Sciences Appliquées ESISA-Fès Maroc. Qu'il s'agisse d'une expérience de télémédecine entre praticiens ou d'articles plus techniques pour des informaticiens, il est vraiment intéressant que nos communautés échangent les points de vue. e-Medisys, de par son thème transdisciplinaire, a pour vocation de réunir les chercheurs, mais aussi les industriels, qui sont acteurs de la télémédecine tant du point de vue médical que du point de vue informatique. C'est ce qui en fait un évènement unique : la réunion des acteurs qui permettront de traiter la télémédecine "de bout en bout". Cette conférence s'est tenue en français et en anglais. Les papiers ont été sélectionnés par un comité de lecture mixte regroupant des spécialistes de renommée dans le domaine de la télémédecine et les nouvelles technologies de l'information. Pour la session de tutoriaux, nous avons invité des sommités comme le Professeur Maresco (Opération Lindberg, argonaute3D), le Professeur Bashshur (Journal on e-health and telemedecine) ou encore le Professeur Lareng (Président de la Société Européenne de Télémédecine) et d'autres sommités dans le domaine de la recherche.
</p>
														<div class="border-div"></div>
														<div class="row">
										<div class="col-md-4">
											<figure>
												{/* <a href="images/images4/emedisys07 esisa mekouar2.gif.jpg" >
													<img src="images/images4/emedisys07 esisa mekouar2.gif.jpg" alt="" class="img555"/>
										</a> */}
											</figure>
										</div>
										<div class="col-md-8">
																					<p>De gauche à droite

																		</p>
																													<p>Pr. Nouredine ALAMI : Vice président la faculté de Médecine et de Pharmacie

																		</p>
																													<p>Dr. Khalid MEKOUAR : Président de la conférence EMEDISYS 07

																		</p>
																													<p>Pr. Mohamed ESSAAIDI : Président IEEE Maroc

																		</p>
																													<p>Pr. Mohamed Salim BOUHLEL : Président de Setit 09 et EMEDISYS 08 à Tunis

																		</p>
																													<p>Pr. Jean-christophe LAPAYRE : Directeur du LIFC (Besançon-France)

																		</p>

										</div>
									</div>
														
														<div class="border-div"></div>
														<div class="row">
										<div class="col-md-4">
											<figure>
											{/* <a href="images/images4/emedisys07 esisa2.jpg" >
												<img src="images/images4/emedisys07 esisa2.jpg" alt="" class="img555"/>
										</a> */}
											</figure>
										</div>
										<div class="col-md-8">
												<p>Participation des élèves de l’ESISA aux grandes conférences internationales une initiative qui est une partie intégrante de sa pédagogie active.

</p>
<p><br/></p>
<p><br/></p>
<p><br/></p>

										</div>
									</div>
														<div class="border-div"></div>
															<div class="row">
										<div class="col-md-4">
											<figure>
											{/* <a href="images/images4/emedisys07 esisa3.jpg" >
												<img src="images/images4/emedisys07 esisa3.jpg" alt="" class="img555"/>
										</a> */}
											</figure>
										</div>
										<div class="col-md-8">
												<p>La force d’ESISA est son corps enseignant (à la hauteur de l’espérance) qui est formé essentiellement de Docteurs d’état qui ont fait leur preuve à l’étranger (USA, France, Canada) et au Maroc.

</p>
<p><br/></p>
<p><br/></p>
<p><br/></p>
										</div>
									</div>
														<div class="border-div"></div>
															<div class="row">
										<div class="col-md-4">
											<figure>
											{/* <a href="images/images4/emedisys07 esisa mekouar3.jpg" >
												<img src="images/images4/emedisys07 esisa mekouar3.jpg" alt="" class="img555"/>
										</a> */}
											</figure>
										</div>
										<div class="col-md-8">
												<p>Un ancien étudiant de l’ESISA, qui a intégré le Laboratoire LIFC de Besançon et qui est un chercheur et parmi le comité d’organisation de la conférence. Reçoit le prix de la main de M. MEKOUAR Khalid Président de l’ESISA et Président de la Conférence EMEDISYS 07.


</p>
<p><br/></p>
<p><br/></p>
<p><br/></p>
										</div>
									</div>
														<div class="border-div"></div>
															<div class="row">
										<div class="col-md-4">
											<figure>
											{/* <a href="images/images4/emedisys07 esisa4.jpg" >
												<img src="images/images4/emedisys07 esisa4.jpg" alt="" class="img555"/>
										</a> */}
											</figure>
										</div>
										<div class="col-md-8">
												<p>La Faculté de Médecine et de Pharmacie de Fès (doyen, Vices Doyen, Professeurs, ….) a joué un rôle primordial à la réussite de cette conférence EMEDISYS 07 Un Carrefour d’information multi disciplinaire Chercheurs en Médecine, Pharmacie, Informatique, Electronique, Télésanté, Réseaux, Télésurveillance, …..


</p>
<p><br/></p>
<p><br/></p>
<p><br/></p>

													<ul class="categries-list">
												    <li>
													<a href="emedisys2007/index.html">Tutorials Sessions</a>
													</li>
																									    <li>
													<a href="emedisys2007/topics.html">Official Ceremony</a>
													</li>
																									    <li>
													<a href="emedisys2007/participants.html">Participants and Organizers</a>
													</li>
																									    <li>
													<a href="emedisys2007/galaDinner.html">Gala Dinner and Social Events</a>
													</li>
													</ul>
										</div>
									</div>
												</div>
											</div>
										</div>
									</div>
								</div>  
                              </div>		
							  	</div>
         </div>
		 
		 <NosPartenaires />
                         
                              
						 </div>
  )
}
