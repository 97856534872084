import React from 'react';
import { useNavigate } from 'react-router-dom';
import NosPartenaires from '../../components/NosPartenaires';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { Button } from 'react-bootstrap'; // Vous pouvez utiliser une bibliothèque comme React Bootstrap ou toute autre pour un bouton stylisé
import { FaPaperPlane, FaDownload } from 'react-icons/fa'; // Exemple d'icône, en utilisant react-icons

export default function Bac5ISITN() {
  const navigate = useNavigate();

  const handleCandidaterClick = () => {
    navigate('/PREINSCRIPTION');
  };

  const handleDownloadClick = () => {
    window.open('images/final/ISITN.pdf', '_blank');
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="course_single_heading text-center">
        
          <div class="iq_heading_1 text-left">
    <h4><span style={{fontSize:'25px', textAlign:"center"}}>              INGÉNIERIE DES SYSTÈMES D'INFORMATION APPLIQUÉE À LA TRANSFORMATION NUMÉRIQUE (ISITN)
</span></h4>
</div>  
          <div className="course-rating" style={{ height: '100%', overflow: 'hidden' }}>
            <Gallery>
              <Item 
                original="images/images4/ISD.jpg"
                thumbnail="images/images4/ISD.jpg"
                width="1920"
                height="1080"
              >
                {({ ref, open }) => (
                  <img 
                    ref={ref} 
                    onClick={open} 
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }} 
                    src="images/final/ISITN.png" 
                    alt="ISITN Image"
                  />
                )}
              </Item>
            </Gallery>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <Button 
              variant="primary" 
              className="d-flex align-items-center mr-2"
              onClick={handleCandidaterClick}
            >
              <FaPaperPlane className="mr-2" />
              Candidater
            </Button>
            <Button 
              variant="secondary" 
              className="d-flex align-items-center"
              onClick={handleDownloadClick}
            >
              <FaDownload className="mr-2" />
              Télécharger la brochure
            </Button>
          </div>
        </div>
      </div>
      {/* <NosPartenaires /> */}
    </div>
  );
}
