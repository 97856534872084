import React from 'react';
import './SocietesPartenaires.css'; // Assurez-vous d'ajouter un fichier CSS correspondant pour les styles

const SocietesPartenaires = () => {
  return (
<>
    <div class="iq_heading_1 text-left">
    <h4><span style={{fontSize:'25px'}}>PARTENAIRES AU MAROC</span></h4>
</div>  
    <section className="partners-section">
      <div className="container">
     
        <div className="partners-content">
          <div className="partners-logos">
            <h5>Nos Partenaires</h5>
            <div className="logo-grid">
              <div className="logo-item">
                <a href="https://www.atline-services.com/home" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/atline.png" alt="Atline" />
                </a>
              </div>
              <div className="logo-item">
                <a href="https://www.capgemini.com/fr-fr/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/capgemini.png" alt="Capgemini" />
                </a>
              </div>
              <div className="logo-item">
                <a href="https://atos.net/fr/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/atos.png" alt="Atos" />
                </a>
              </div>
              <div className="logo-item">
                <a href="https://exchange.leyton.com/owa/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/leyton.png" alt="Leyton" />
                </a>
              </div>
              <div className="logo-item">
                <a href="https://www.tesselategroup.com/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/tesselate.png" alt="Tesselate" />
                </a>
              </div>

              <div className="logo-item">
                <a href="https://www.orange.ma/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/orange.png" alt="orange" />
                </a>
              </div>

              <div className="logo-item">
                <a href="https://www.thalesgroup.com/fr" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/thales.png" alt="thales" />
                </a>
              </div>

              <div className="logo-item">
                <a href="https://www.alten.ma/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/alten.png" alt="alten" />
                </a>
              </div>

              <div className="logo-item">
                <a href="https://www.ibm.com/fr-fr" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/ibm.png" alt="ibm" />
                </a>
              </div>


              <div className="logo-item">
                <a href="https://www.cgi.com/maroc/fr-fr" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/cgi.png" alt="CGI" />
                </a>
              </div>

              <div className="logo-item">
                <a href="https://www.ocpgroup.ma/fr" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/ocp.png" alt="OCP" />
                </a>
              </div>

              <div className="logo-item">
                <a href="https://www.concentrix.com/fr/" target="_blank" rel="noopener noreferrer">
                  <img src="images/partenaires/concentrix.png" alt="Concentrix" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default SocietesPartenaires;
