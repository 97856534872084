import React from 'react';
import './Stages.css'; // Assurez-vous d'ajouter un fichier CSS correspondant pour les styles

const Stages = () => {
  return (

<>
    <div class="iq_heading_1 text-left">
    <h4><span style={{fontSize:'25px'}}>STAGES</span></h4>
</div>  
    <section className="stage-info">

    
      <div className="container">
           
        <div className="content">
          <h3>Objectifs du Stage</h3>
          <br/>          <br/>

          <div className="objectives">
            <div className="objective">
              <h4>Pour l'Élève Ingénieur</h4>
              <br/>

              <p>
                Le stage est conçu pour être une expérience pédagogique enrichissante, offrant une opportunité de mettre en pratique ses compétences théoriques et techniques acquises au cours de sa formation. Il est essentiel que le stagiaire fasse preuve de motivation et d’engagement pour maximiser l’apprentissage et la contribution à l’entreprise.
              </p>
            </div>
            <div className="objective">
              <h4>Pour l’Entreprise</h4>
              <br/>

              <p>
                Le stagiaire apporte une perspective fraîche et des compétences actuelles, pouvant susciter une réflexion sur les pratiques en place et apporter de nouvelles idées. L’entreprise bénéficie également de l’énergie et des connaissances du stagiaire pour ses projets.
              </p>
            </div>
          </div>
          <br/>
          <br/>

          <h3>Rôle des Encadrants</h3>
          <br/>

          <div className="roles">
            <div className="role">
              <h4>Maître de Stage</h4>
              <br/>

              <p>
                Le maître de stage joue un rôle crucial en guidant le stagiaire et en fournissant des explications et un feedback sur le travail réalisé. Cette démarche exige une analyse critique des pratiques en vigueur au sein de l’entreprise.
              </p>
            </div>
            <div className="role">
              <h4>Tuteur à l’ESISA</h4>
              <br/>

              <p>
                Le tuteur assure le suivi académique du stagiaire et veille à la conformité du stage avec les objectifs pédagogiques de l’école.
              </p>
            </div>
          </div>
          <br/>
          <br/>

          <h3>Évaluation du Stage</h3>
          <br/>

          <div className="evaluation">
            <div className="criteria">
              <h4>Travail Réalisé</h4>
              <br/>

              <p>
                Évalué par le maître de stage, prenant en compte les résultats techniques, l'initiative, l'autonomie et la capacité à travailler en équipe.
              </p>
            </div>          <br/>

            <div className="criteria">
              <h4>Rapport de Stage</h4>
              <p>
                Noté conjointement par le maître de stage et le tuteur, cette évaluation considère l’organisation, la qualité technique et l’utilité du rapport pour l’entreprise.
              </p>
            </div>          <br/>

            <div className="criteria">
              <h4>Présentation Orale</h4>
              <br/>

              <p>
                Jugée sur la structure, la clarté et la maîtrise des réponses aux questions, cette évaluation est effectuée par le maître de stage et le tuteur.
              </p>
            </div>
          </div>
          
          <p>
            La note finale du stage est la moyenne des trois évaluations.
          </p>
          <br/>
          <br/>

          <h3>Dépôt des Offres de Stage</h3>
          <br/>

          <p>
            Les entreprises intéressées pour proposer des stages aux étudiants d'ESISA peuvent soumettre leurs offres en envoyant un courriel à : <strong>info@esisa.ac.ma</strong>.
          </p>
        </div>
      </div>
    </section>
    </>
  );
};

export default Stages;
