import React, { Component } from "react";
import Slider from "react-slick";
import "./slider.css";


import image0 from "../../images/new/image0.png"
import image1 from "../../images/new/image1.png"
import image2 from "../../images/new/image2.png"
import image3 from "../../images/new/image3.png"
import image4 from "../../images/new/image4.png"
import image5 from "../../images/new/image5.png"
import image6 from "../../images/new/image6.png"
import image7 from "../../images/new/image7.png"
import image8 from "../../images/new/image8.png"
import image9 from "../../images/new/image9.png"
import image10 from "../../images/new/image10.png"





export default class PauseOnHover extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,

      appendDots: dots => (
        <div
          style={{
            width: '100%',
            bottom: '10px',
            height: 'auto',
            position: 'absolute',
            textAlign: 'center'
          }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 40000,
            pauseOnHover: true,
          }
        }
      ]
    }
   
    return (


        <Slider {...settings}>
                        
                        <img className="image" src={image2} alt="image2" />

                         <img className="image"  src={image1} alt="image1" />
                         <img className="image"  src={image3} alt="image3" />
                         <img className="image"  src={image4} alt="image4" />
                         <img className="image"  src={image5} alt="image5" />
                         <img className="image"  src={image6} alt="image6" />
                         <img className="image"  src={image7} alt="image7" />
                         <img className="image"  src={image0} alt="image0" />

                         <img className="image"  src={image8} alt="image8" />
                         <img className="image"  src={image9} alt="image9" />
                         <img className="image" src={image10} alt="image10" />
               
                        


               

                     
      
       
        </Slider>

    );
  }
}