import React from 'react'

export default function footerstrip() {
  return (
    	<div class="footer_strip">
				<h5><a target="_blank" href="http://www.esisa.ac.ma">Copyright © 2024 ESISA - Tous droits Réservés </a></h5>
				 <a class="lien" href="http://www.esisa.ac.ma"> www.esisa.ac.ma </a>
			</div>
  )
}
