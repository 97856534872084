import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './Themes_recherche.css'; // Importez le fichier CSS pour les styles

export default function ThemesRecherche() {
  return (


	<>
		  <div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
			THÈMES DE RECHERCHE
          </span>
        </h4>
      </div>


	  <div className="themes-recherche-container">
      <div className="content-wrapper">
      
        <div className="content">
          <p className="introduction">
            La recherche a été initiée à l'ESISA par son Président et Directeur pédagogique M. MEKOUAR Khalid après son palmarès glorieux en France (Nice Sophia Antipolis) Prix Sadi Carnot 1991.
          </p>
          <ul className="themes-list">
            <li><a href="#">Bases de Données évoluées</a></li>
            <li><a href="#">Bases de Données MultiMedia</a></li>
            <li><a href="#">Intelligence Artificielle</a></li>
            <li><a href="#">Big Data</a></li>
            <li><a href="#">Data sciences</a></li>
            <li><a href="#">Internet des Objets (IOT)</a></li>
            <li><a href="#">Structures de Données évoluées</a></li>
            <li><a href="#">Systèmes distribués</a></li>
            <li><a href="#">Systèmes Experts</a></li>
            <li><a href="#">Traitement d'Images</a></li>
            <li><a href="#">Télé Médecine</a></li>
            <li><a href="#">Télé Enseignement</a></li>
            <li><a href="#">EIAO: Enseignement Intelligent assisté par ordinateur</a></li>
          </ul>
          <p className="conclusion">
            L'ESISA est considérée comme un cycle préparatoire à la recherche. Elle donne le goût et les moyens nécessaires de la recherche à ses jeunes étudiants en leur apprenant les bonnes démarches et méthodes pour y aboutir et en les initiant aux nouvelles technologies.
          </p>
        </div>
      </div>
	  <div className="nos-partenaires">
<NosPartenaires/>    </div>
    
    </div>
	</>
  
  );
}
