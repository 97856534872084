import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';
import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Procedure_admission() {
  return (
    <div class="row">
    <div class="col-md-8">							  
    <div class="course_single_heading">				
        <div class="iq_heading_1 text-left">
            <h4><span style={{fontSize:'25px'}}>PARCOURS ET PROCEDURE D'ADMISSION</span></h4>
        </div>
        <div class="course-rating"style={{overflow: 'scroll',height:'1000px',overflowX:'hidden'}}> 
        <p>L'admission à l'ESISA est ouverte aux titulaires du baccalauréat scientifique, économique, technique, ou diplomes équivalents. La sélection des candidats et leur admission s'inspire du recrutement des cadres en entreprise : Dossier plus Entretien. Les entretiens permettent aux candidats de démontrer leurs aptitudes, leur personnalité et leur motivation à intégrer une école supérieure d'informatique comme l'ESISA.</p>
        <Gallery>

<Item original="images/images4/Parcours2023.jpg"
  thumbnail="images/images4/Parcours2023.jpg"
  width="750"
  height="1000"
>
  {({ ref, open }) => (
   
    <div className="course-rating" style={{ height: '100%', overflow: 'hidden' }}>
            <img 
              src="images/final/Nouvelles_fillieres.png" 
              alt="IS2IA Image"
              style={{ width: '100%', height: 'auto' }} 
            />
          </div>  
  )}
</Item></Gallery>

      

       </div>		
      </div>
      </div>
      <NosPartenaires />
                         
                              
      </div>
)
}
