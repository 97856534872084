import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';
import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Conferences_etranger() {
  return (
	<div class="row">
        <div class="col-md-8">							  
								<div class="course_single_heading">				
		                            <div class="iq_heading_1 text-left">
		                                <h4><span style={{fontSize:'25px'}}>CONFÉRENCES A L'ETRANGER</span></h4>
		                            </div>
									<div class="course-rating" style={{overflow: 'scroll',height:'1000px',overflowX:'hidden'}}>
                   
                  <div class="about_courses">	
									
									<div class="about_courses_thumb">
									
			                            
			                        
				                        <div class="iq_tab_menu">
				                        	<ul id="tabs" data-tabs="tabs">
				                            	<li class="active"><a data-toggle="tab" href="#Nokia" style={{fontSize:'13px'}}>Nokia Paris-Saclay 2016</a></li>
				                                <li><a data-toggle="tab" href="#SETIT9" style={{fontSize:'13px'}}>SETIT - Hammamet 2009</a></li>
												<li><a data-toggle="tab" href="#SETIT7" style={{fontSize:'13px'}}>SETIT - Hammamet 2007</a></li>
												 <li><a data-toggle="tab" href="#DFMA" style={{fontSize:'13px'}}>DFMA - Besançon 2005</a></li>
				                            </ul>
				                        </div>
				                 
			                            <div id="my-tab-content" class="tab-content">
                            				<div class="tab-pane active" id="Nokia">
												<div class="about_courses_thumb_capstion">
													<h5 style={{color:'#1962e5',font:'bold'}}>Journée nationale organisée le 29 Novembre 2016 à la Cite´ de l’Innovation, Nokia Paris-Saclay</h5>
													<h6 style={{color:'#1962e5'}}>Thématiques</h6>
													<img src="images/images4/3(1).jpg" alt="Image Here"/>
													<p>La journée a pour objectif de valoriser les recherches et les réalisations basées sur les technologies numériques et les objets connectés et leurs usages quotidiens.

</p>
													<h6 style={{color:'#1962e5'}}>PRÉSENTATION</h6>
													<img src="images/images4/3(2).jpg" alt="Image Here"/>
													<p>Cette Journée consacrée aux nouveaux défis de l’Internet des Objets propose de porter un regard qui intègre d’une part les problématiques de facteurs humains et problématiques technologiques, et d’autre part la question du rôle que joue aujourd’hui les objets connectés dans la vie quotidienne comme la santé, l’habitat, l’automobile, l’assurance, qui sont en train d’être bouleversés par cette mutation du réseau. Cette journée s'inscrit dans la continuité des évenements académiques sur le sujet de l'Internet des Objets.

</p>	
												</div>
											
											
									
											</div>
											<div class="tab-pane" id="SETIT9">
											<div class="about_courses_thumb_capstion">
						                            <h5 style={{color:'#1962e5',font:'bold'}}>WORKSHOP DU 12 MARS 2010 SOUS LE THÈME : LA FORMATION AU SERVICE DU MÉTIER DE L'OFFSHORE</h5>
													<img src="images/images4/stt3.gif" alt="Image Here"/>
												</div>
											</div>
																<div class="tab-pane" id="SETIT7">
											<div class="about_courses_thumb_capstion">
						                            <h5 style={{color:'#1962e5',font:'bold'}}>Sciences of Electronics, Technologies of Information and Telecommunication, Hammamet 2007
													</h5>
													<center>
														<img src="images/images4/stt2.gif" alt="Image Here"/>
													<p></p>
													<p><b>Honorary Chair</b></p>
													<p><b>Lazhar BOUOUNI</b></p>
													<p><b>Minister of Higher Education, Scientific Research and Technology</b></p>
													<p><b>Conference Chairmen</b></p>
													<p><b>Mohamed Salim BOUHLEL (ISBS-Sfax, Tunisia)</b></p>
													<p><b>Bassel SOLAIMAN (ENST-Br, France</b></p>
													</center>
													<div class="border-div"></div>
													  <div class="main-gallery">
                      
						</div>

												</div>
											</div>
																<div class="tab-pane" id="DFMA">
											<div class="about_courses_thumb_capstion">
						                            <h5 style={{color:'#1962e5',font:'bold'}}>THE FIRST INTERNATIONAL CONFERENCE ON DISTRIBUTED FRAMEWORKS FOR MULTIMEDIA APPLICATION DFMA 2005 - FEBRUARY 6-9, 2005 - BESANÇON, FRANCE

</h5>
  <div class="main-gallery">
                       
						<p>Depuis l’année 2004 l’ESISA n’a pas cessé de donner de l’importance à la recherche scientifique en informatique au sein de son cursus universitaires.

</p>
						</div>

													<div class="border-div"></div>
  <div class="main-gallery">
                      
						</div>
						<p>Résultat : un grand nombre parmi ses lauréats actuellement sont en préparation de thèses dans différents laboratoires en France et notamment au LIFC et LSIS.</p>

												</div>
											</div>
								
										</div>
									</div>
								</div>

                 </div>		
							  	</div>
                     </div>
					 <NosPartenaires />
                         
                              
					 </div>
)
}

