import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';

import { Link } from 'react-router-dom';
import { Routes, Route, Outlet } from 'react-router-dom';
export default function Galerie() {
  return (
    <div class="iq_content_wrap" style={{backgroundColor:'white'}}>
  
    
             <section class="center-slider-wrap">
              <div class="iq_heading_1 text-center">
                  <h4><span>Galerie</span></h4>
               </div>
        <div id="filters" class="iq_gallery_menu">
                        <ul id="filterable-item-filter-1">
                        <li> <Link  to="/Galerie/Galimg12" style={{marginBottom: '10px'}}>Soirée d'integration - 2022/2023</Link></li>
                        <li> <Link  to="/Galerie/Galimg1" style={{marginBottom: '10px'}}>Soirée de gala Lartiste - 2017</Link></li>
                        <li> <Link  to="/Galerie/Galimg2" style={{marginBottom: '10px'}}>Soirée de gala DOUZI - 2016</Link></li>
                        <li> <Link  to="/Galerie/Galimg3" style={{marginBottom: '10px'}}>Soirée de fin d'année - 2015</Link></li>
                        <li> <Link  to="/Galerie/Galimg4" style={{marginBottom: '10px'}}>Activités Parascolaires</Link></li>
                        <li> <Link  to="/Galerie/Galimg5" style={{marginBottom: '10px'}}>Conférence Internationale NDIDO 2017</Link></li>
                        <li> <Link  to="/Galerie/Galimg6" style={{marginBottom: '10px'}}>Relations Entreprises</Link></li>
                        <li> <Link  to="/Galerie/Galimg7" style={{marginBottom: '10px'}}>Compétition annuelle « Public Speaking »</Link></li>
                        <li> <Link  to="/Galerie/Galimg8" style={{marginBottom: '10px'}}>Moroccan Collegiate Programming Contest</Link></li>
                        <li> <Link  to="/Galerie/Galimg9" style={{marginBottom: '10px'}}>Les Forums Etudiants</Link></li>
                        <li> <Link  to="/Galerie/Galimg10" style={{marginBottom: '10px'}}>Cérémonie de remise des diplômes - 2018</Link></li>
                        <li> <Link  to="/Galerie/Galimg11" style={{marginBottom: '10px'}}>Cérémonie de remise des diplômes - 2021</Link></li>       
                        <li> <Link  to="/Galerie/Galimg13" style={{marginBottom: '10px'}}>Cérémonie de remise des diplômes - 2022</Link></li>  
                        </ul>
                    </div>
                    <nav>
                   

                    </nav>
                   <Outlet />
          
                   
          
</section>

</div>

                         
                              
                     
              )
            }

