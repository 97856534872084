import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';
import "./ecolePartenaire.css"

export default function paris8() {
    return (
        <div className="divecole2 fifty">
            <div className='imglogopartnaire'>
                <img 
                    src="images/partenaire/paris81.png" 
                    className='logoPartenaire' 
                />
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université de Paris 8 <Rentree />
            </a>
            <br /> <br />
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL AISSAOUI Oussama - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}> TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL RHAZI Imane - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                SENHAJI MIMIDI Zainab - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                HEFIED Anas - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL FAZAZI Oumayma - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENKIRANE Nisrine - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}> Numérique: E & T</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                CHAHDI Ayat - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Numérique: E & T</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ECH-CHAOUI Ali - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Numérique: E & T</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AKESBI Mohamed El Mehdi - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Numérique: H & N</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                KFAL Youssef - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Numérique: H & N</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                TOUIL Alae - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Numérique: H & N</span>
            </p>
        </div>
    )
}
