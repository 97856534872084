import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './SystemeEnseignement.css'; // Importez le fichier CSS pour les styles

export default function SystemeEnseignement() {
  return (


	<>
			  <div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
			SYSTÈME D'ENSEIGNEMENT
          </span>
        </h4>
      </div>

	  <div className="systeme-enseignement-container">
      <div className="content-wrapper">
      
        <div className="content">
          <p className="intro">
            L'enseignement à l'école ESISA est fondé sur un fonctionnement par semestre, basé sur le système modulaire. A l'école ESISA, l'enseignement est dispensé en 2 cycles étalés sur 5 années d'études :
          </p>
          <ul className="cycle-list">
            <li>
              <a href="#">1er Cycle (Bac +3 ans)</a>
            </li>
            <li>
              <a href="#">2ème Cycle (2 ans après Bac +3) M1 et M2, à l’ESISA ou M2 dans des universités étatiques en France suite au M1 à l'ESISA ou Diplôme d'Ingénieur d’état en France (2 ans).</a>
            </li>
          </ul>
          <p className="cycle-description">
            <strong>Le 1er Cycle (Bac +3 ans)</strong>, comprenant trois années d'études : 2 années préparatoires et une 3ème année axée sur l’approfondissement des acquis.
          </p>
          <h5 className="section-title">1ère et 2ème années</h5>
          <p>
            L'objectif est d'initier l'étudiant aux domaines de l'informatique avec ses différentes disciplines, et de développer ses compétences en développement informatique à travers une formation centrée sur la programmation, les structures de données, les langages de programmation, les bases de données, les systèmes d'information, et les réseaux informatiques.
          </p>
          <h5 className="section-title">3ème année</h5>
          <p>
            L'objectif est de former des assistants débutants à la maîtrise d’œuvre (MOE) et à la maîtrise d’ouvrage (MO), dans les domaines de la conception et de l’administration des systèmes d’information : bases de données, réseaux, architecture technique, développement d’applications, déploiement logiciel, intégration d’exploitation, analyse et traitements statistiques.
          </p>
          <p className="cycle-description">
            <strong>Le 2ème Cycle (2 ans après Bac +3)</strong> comprend 2 années d’études (4ème et 5ème années). Il a pour objectif principal de faire évoluer les compétences de l'étudiant vers la maîtrise des grands systèmes et leurs architectures, avec une grande expérience théorique et technique dans le domaine du développement basé sur les nouvelles technologies informatiques. Il se clôt par la réalisation d'un projet de fin d'études dans le secteur industriel, mené principalement au sein des entreprises actives dans le développement informatique à l'échelle internationale et dans le domaine du développement offshore.
          </p>
          <p>
            L'école ESISA met l'accent sur l'importance de la communication en français et en anglais dans les métiers de l'ingénierie. Tout au long des années d'études, les étudiants sont préparés à une meilleure adaptation linguistique pour faciliter leur intégration dans les cursus finaux à l'étranger et ouvrir les portes des métiers de l'avenir.
          </p>
          <p>
            En outre, les entretiens de sélection pour les différentes écoles et universités partenaires se passent en français et en anglais. Les étudiants de l'ESISA sont appelés à atteindre le niveau de TOEIC (Test Of English for International Communication) en fin de 4ème année.
          </p>
        </div>
      </div>

	  <div className="nos-partenaires">
<NosPartenaires/>    </div>
    </div>
	</>
    
  );
}
