import React from 'react';
import ReactDOM, { render } from 'react-dom';
import App from './App';
import { BrowserRouter} from 'react-router-dom';


ReactDOM.render(
    
    <BrowserRouter basename='/'>
    <App />
    </BrowserRouter>
        ,document.getElementById('root'));