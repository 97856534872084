import React from 'react';
import { Link } from 'react-router-dom';

export default function NavbarMob() {
  return (
    <div id="mg-responsive-navigation" className="dl-menuwrapper">
      <button className="dl-trigger">Menu</button>
      <ul className="dl-menu">
        <li className="menu-item mg-parent-menu">
          <li className="active"><Link to="/">ACCUEIL</Link></li>
        </li>
        <div>
          <ul>
            <li><Link to="/Etudier_ESISA">Etudier à l'ESISA</Link></li>
          </ul>
        </div>
        <li className="menu-item mg-parent-menu">
          <a>A PROPOS DE L'ESISA</a>
          <ul className="dl-submenu">
            <li><Link className="navcolor" to="/Mot_president">Mot du président</Link></li>
            <li><Link to="/Objectifs_ESISA">Objectifs de l'ESISA</Link></li>
            <li><Link to="/Voix_ESISA">La voix des anciens</Link></li>
            <li><Link to="/Conferences_ESISA">Conférences à l'ESISA</Link></li>
            <li><Link to="/Conferences_etranger">Conférences à l'étranger</Link></li>
            <li><Link to="/Workshop">Workshop</Link></li>
            <li><Link to="/Themes_recherche">Thèmes de recherche</Link></li>
          </ul>
        </li>
        <li className="menu-item mg-parent-menu">
          <a>ENSEIGNEMENT</a>
          <ul className="dl-submenu">
            <li><Link to="/Systeme_enseignement">Système d'enseignement</Link></li>
            <li><Link to="/Procedure_admission">Procédure d'admission</Link></li>
            <li><a href="/../../pdf/Reglement_interieur.pdf" target="_blank">Règlement intérieur</a></li>
            <li><Link to="/Bac3">Bac + 3</Link></li>
            <li>
              <a>Bac + 5</a>
              <ul className="dl-submenu">
                <li><Link to="/Bac5ISI">ISI</Link></li>
                <li><Link to="/Bac5ISD">ISD</Link></li>
                <li><Link to="/Bac5IS2IA">IS2IA</Link></li>
                <li><Link to="/Bac5ISITN">ISITN</Link></li>
              </ul>
            </li>
            <li><Link to="/Ccna">Formation CCNA(CISCO)</Link></li>
          </ul>
        </li>
        <li className="menu-item mg-parent-menu">
          <a>L'INTERNATIONAL</a>
          <ul className="dl-submenu">
            <li><Link to="/ESISA_International">ESISA et L'international</Link></li>
            <li><Link to="/Admis_Etranger">Admis à L'étranger</Link></li>
            <li><Link to="/Nos_Partenaires">Nos partenaires</Link></li>
          </ul>
        </li>
        <li className="menu-item mg-parent-menu">
          <a>ENTREPRISES ET STAGES</a>
          <ul className="dl-submenu">
            <li><Link to="/ESISAEntreprises">ESISA-Entreprises</Link></li>
            <li><Link to="/Stages">Stages</Link></li>
            <li><Link to="/Emplois">Emplois</Link></li>
            <li><Link to="/SocietesPartenaires">Sociétés partenaires</Link></li>
          </ul>
        </li>
        <li className="menu-item mg-parent-menu">
          <a>AUTRES</a>
          <ul className="dl-submenu">
            <li><Link to="/Presse">Presse</Link></li>
            <li><Link to="/Galerie">Galerie</Link></li>
            {/*<li><Link to="/Contactez-nous">Contactez-nous</Link></li>*/}
          </ul>
        </li>
        <li className="menu-item mg-parent-menu">
          <Link to="/PREINSCRIPTION">PRE-INSCRIPTION</Link>
        </li>
        <li className="menu-item mg-parent-menu">
          <a>ESPACE ETUDIANT</a>
          <ul className="dl-submenu">
            <li><a href="https://classroom.google.com/h">COURS EN LIGNE</a></li>
            <li><a href="https://esisa.org.ma/login/index.php">Moodle</a></li>
            <li><a href="https://esisa-portail-intranet.vercel.app/">Intranet</a></li>
          </ul>
        </li>
        <li class="active">
            <Link to="https://orbite.ma/esisa/">VISITE VIRTUELLE</Link>
          </li>
      </ul>
    </div>
  );
}
