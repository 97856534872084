import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './Presse.css'; // Importer les styles CSS

export default function Presse() {
  return (

    <>
         <div class="iq_heading_1 text-left">
    <h4><span style={{fontSize:'25px'}}>PRESSE</span></h4>


    <div className="presse-container">
      <div className="presse-content">
        <div className="presse-heading">
        </div>
        <div className="presse-body">
       
          <table className="presse-table">
            <tbody>
              <tr>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/matin04.pdf">Le matin 2004</a>
                </td>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/matin05.pdf">Le matin 2005</a>
                </td>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/opinion07.pdf">L'opinion 2007</a>
                </td>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/opinion072.pdf">L'opinion 2007</a>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/liberation10.pdf">Liberation 2010</a>
                </td>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/lopinion10.pdf">L'opinion 2010</a>
                </td>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/economiste_frontieres_numeriques_2014.pdf">L'Economiste 2014</a>
                </td>
                <td>
                  <img src="images/images4/pdf.png" alt="pdf icon" className="pdf-icon" />
                  <a href="pdf/economiste_frontieres_numeriques_2014_2.pdf">L'Economiste 2014</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="nos-partenaires">
<NosPartenaires/>    </div>
    
      </div>
</div>  
    </>
   
  );
}


