import React from "react";
import NosPartenaires from "../../components/NosPartenaires";
import { Gallery, Item } from "react-photoswipe-gallery";
export default function Workshop() {
  return (

	<>
		     <div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
			WORKSHOPS ORGANISÉS PAR L'ESISA
          </span>
        </h4>
      </div>

	  <div class="row">
      <div class="col-md-8">
        <div class="course_single_heading">
        
          <div
            class="course-rating"
            style={{
              overflow: "scroll",
              height: "1000px",
              overflowX: "hidden",
            }}
          >
            <div class="about_courses">
              <div class="about_courses_thumb">
                <div class="iq_tab_menu">
                  <ul id="tabs" data-tabs="tabs">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        href="#description"
                        style={{ fontSize: "13px" }}
                      >
                        WorkShop - 2011
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#curriculum"
                        style={{ fontSize: "13px" }}
                      >
                        WorkShop - 2010
                      </a>
                    </li>
                  </ul>
                </div>

                <div id="my-tab-content" class="tab-content">
                  <div class="tab-pane active" id="description">
                    <div class="about_courses_thumb_capstion">
                      <h5 style={{ color: "#1962e5", font: "bold" }}>
                        WORKSHOP DU 12 MARS 2011 SOUS LE THÈME : LA FORMATION AU
                        SERVICE DE L'ENTREPRISE
                      </h5>
                      <h6 style={{ color: "#1962e5" }}>Thématiques</h6>
                      <p>
                        La journée a pour objectif de valoriser les recherches
                        et les réalisations basées sur les technologies
                        numériques et les objets connectés et leurs usages
                        quotidiens
                      </p>
                      <p>
                        Dans le cadre de son orientation stratégique visant
                        l'amélioration de la qualité de la formation fournie à
                        ses élèves ingénieurs, l'école ESISA (Ecole Supérieure
                        d'Ingénierie en Sciences Appliquées) a organisé le 12
                        Mars 2011 une journée d'étude à l'Hôtel Jnane Palace FES
                        sous le thème :
                      </p>
                      <p>La Formation au Service de l'Entreprise</p>
                      <p>
                        La journée vise à réunir un public constitué à la fois
                        d'entreprises actives dans le domaine du développement
                        offshore, élèves ingénieurs, formateurs experts en
                        technologies de l'information et de la communication et
                        l' ESISA qui a participé depuis l'an 1999 dans la
                        formation de jeunes ingénieurs confirmés, déjà actifs au
                        niveau national et international dans les domaines des
                        Technologies Avancées, Offshore & TIC et Recherche &
                        Développement.
                      </p>
                      <p>
                        Les objectifs de la journée se résument principalement
                        dans les points suivants :
                      </p>
                      <ul class="categries-list">
                        <li>
                          <a>
                            Sensibilisation à la nécéssité de la bonne formation
                            avant et après le recrutement.
                          </a>
                        </li>
                        <li>
                          <a>
                            La sensibilisation des institutions d'enseignement
                            supérieur, d'adapter les programmes aux besoins de
                            l'entreprise.
                          </a>
                        </li>{" "}
                        <li>
                          <a>
                            Participation au concours de recrutement et aux
                            formations pour l'insertion.
                          </a>
                        </li>{" "}
                        <li>
                          <a>
                            Participation aux formations des collaborateurs des
                            entreprises actives dans le domaine du développement
                            offshore et autre
                          </a>
                        </li>{" "}
                        <li>
                          <a>Les formations en alternance.</a>
                        </li>{" "}
                        <li>
                          <a>
                            Créer une synergie entre les chercheurs et les
                            industriels pour la promotion de la recherche et
                            développement.
                          </a>
                        </li>
                        <li>
                          <br />
                        </li>
                      </ul>

                      <div class="main-gallery">
                        <Gallery>
                          <Item
                            original="images/images4/workshop11 .jpg"
                            thumbnail="images/images4/workshop11 .jpg"
                            width="450"
                            height="700"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                width="450"
                                height="700"
                                src="images/images4/workshop11 .jpg"
                              />
                            )}
                          </Item>
                        </Gallery>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="curriculum">
                    <div class="about_courses_thumb_capstion">
                      <h5 style={{ color: "#1962e5", font: "bold" }}>
                        WORKSHOP DU 12 MARS 2010 SOUS LE THÈME : LA FORMATION AU
                        SERVICE DU MÉTIER DE L'OFFSHORE
                      </h5>
                      <h6 style={{ color: "#1962e5" }}>Thématiques</h6>
                      <p>
                        Dans le cadre de son orientation stratégique visant
                        l'amélioration de la qualité de la formation fournie à
                        ses élèves ingénieurs, l'école ESISA (Ecole Supérieure
                        d'Ingénierie en Sciences Appliquées) organise le 13 Mars
                        2010 une journée d'étude à l'Hôtel Jnane Palace FES sous
                        le thème :
                      </p>
                      <h6 style={{ color: "#1962e5" }}>
                        La Formation au Service du métier de L'Offshore
                      </h6>
                      <p>
                        La journée vise à réunir un public constitué à la fois
                        d'entreprises multinationales actives dans le domaine du
                        développement offshore, élèves ingénieurs, formateurs
                        experts en technologies de l'information et de la
                        communication et l' ESISA qui a participé depuis l'an
                        1999 dans la formation de jeunes ingénieurs confirmés,
                        déjà actifs dans le domaine de l'offshore.
                      </p>
                      <p>
                        Les objectifs de la journée se résument principalement
                        dans les points suivants :
                      </p>
                      <ul class="categries-list">
                        <li>
                          <a>
                            La nécessité de la bonne formation avant et après le
                            recrutement.
                          </a>
                        </li>
                        <li>
                          <a>
                            Sensibilisation des institutions d'enseignement
                            supérieur, d'adapter les programmes aux besoins de
                            l'entreprise.
                          </a>
                        </li>{" "}
                        <li>
                          <a>
                            Participation au concours de recrutement et aux
                            formations pour l'insertion.
                          </a>
                        </li>{" "}
                        <li>
                          <a>
                            Participation aux formations des collaborateurs des
                            entreprises actives dans le domaine du développement
                            offshore.
                          </a>
                        </li>
                      </ul>

                      <div class="main-gallery">
                        <Gallery>
                          <Item
                            original="images/images4/workshop10 .jpg"
                            thumbnail="images/images4/workshop10 .jpg"
                            width="450"
                            height="700"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                width="450"
                                height="700"
                                src="images/images4/workshop10 .jpg"
                              />
                            )}
                          </Item>
                        </Gallery>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NosPartenaires />
    </div>
	</>
  
  );
}
