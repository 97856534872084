import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg7() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">


<Gallery>

<Item original="../images/images5/publicS/1.jpg"
  thumbnail="../images/images5/publicS/1.jpg"
  width="1024"
  height="768"
>
  {({ ref, open }) => (
   
  <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/publicS/1.jpg" />
  
  )}
</Item>
<Item original="../images/images5/publicS/2.jpg"
  thumbnail="../images/images5/publicS/2.jpg"
  width="1024"
  height="768"
>
  {({ ref, open }) => (
   
  <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/publicS/2.jpg" />
  
  )}
</Item>
<Item original="../images/images5/publicS/3.jpg"
  thumbnail="../images/images5/publicS/3.jpg"
  width="1024"
  height="768"
>
  {({ ref, open }) => (
   
  <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/publicS/3.jpg" />
  
  )}
</Item>
<Item original="../images/images5/publicS/4.jpg"
  thumbnail="../images/images5/publicS/4.jpg"
  width="1024"
  height="768"
>
  {({ ref, open }) => (
   
  <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/publicS/4.jpg" />
  
  )}
</Item>
<Item original="../images/images5/publicS/5.jpg"
  thumbnail="../images/images5/publicS/5.jpg"
  width="1024"
  height="768"
>
  {({ ref, open }) => (
   
  <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/publicS/5.jpg" />
  
  )}
</Item>
<Item original="../images/images5/publicS/6.jpg"
  thumbnail="../images/images5/publicS/6.jpg"
  width="1024"
  height="768"
>
  {({ ref, open }) => (
   
  <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/publicS/6.jpg" />
  
  )}
</Item>
</Gallery>

    </div>
  )
}
