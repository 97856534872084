import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg11() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">
<Gallery>
<Item original="../images/images5/remise21/1.png"
      thumbnail="../images/images5/remise21/1.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/1.png" />
      
      )}
    </Item>



    <Item original="../images/images5/remise21/3.png"
      thumbnail="../images/images5/remise21/3.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/3.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/4.png"
      thumbnail="../images/images5/remise21/4.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/4.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/5.png"
      thumbnail="../images/images5/remise21/5.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/5.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/6.png"
      thumbnail="../images/images5/remise21/6.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/6.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/7.png"
      thumbnail="../images/images5/remise21/7.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/7.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/8.png"
      thumbnail="../images/images5/remise21/8.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/8.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/9.png"
      thumbnail="../images/images5/remise21/9.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/9.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/10.png"
      thumbnail="../images/images5/remise21/10.png"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/remise21/10.png" />
      
      )}
    </Item>

    <Item original="../images/images5/remise21/11.png"
      thumbnail="../images/images5/remise21/11.png"
      width="474"
      height="963"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="474.09px" height="963px" src="../images/images5/remise21/11.png" />
      
      )}
    </Item>
    <Item original="../images/images5/remise21/2.png"
      thumbnail="../images/images5/remise21/2.png"
      width="474"
      height="963"
    >
      {({ ref, open }) => (
      
      <img ref={ref} onClick={open} width="474.09px" height="963px" src="../images/images5/remise21/2.png" />
      
      )}
    </Item>

  )
</Gallery>

          </div>
  )
}
