import React from "react";
import Slider from "react-slick";
import "./Actualites.css";

export default function Actualites() {
  const actualites = [
    {
      imgSrc: "images/actu/cluster.jpg",
      altText: "Cluster C19",
      title: "Convention ESISA - C-19",
      description:
        "ESISA et Cluster C19 ont conclu une convention pour développer des jeux vidéo, marquant ainsi une collaboration innovante dans le domaine de l'éducation et du divertissement numérique.",
    },
    {
      imgSrc: "images/actu/master.jpg",
      altText: "Nouveaux Masters",
      title: "Nouveaux Masters",
      description:
      "Nous sommes ravis de vous présenter deux nouveaux masters : le Master IS2IA, qui forme des experts en systèmes d'information, intelligence artificielle et machine learning pour créer des solutions innovantes, et le Master ISITN, qui prépare des spécialistes de la transformation digitale des entreprises avec une expertise en technologies émergentes et stratégies numériques.",},
      {
      imgSrc: "images/actu/atline.jpg",
      altText: "Atline",
      title: "Convention ESISA - Atline",
      description:
        "ESISA a signé une convention avec Atline, leader français des appels d'offres. L'accord vise à renforcer les compétences professionnelles par des programmes d'alternance innovants, enrichissant ainsi l'éducation et préparant les étudiants au monde du travail.",
    },
    {
      imgSrc: "images/actu/analytica.jpg",
      altText: "ESISA Analytica",
      title: "Lancement du Laboratoire ESISA ANALYTICA",
      description:
        "ESISA est fière d'annoncer le lancement de son laboratoire 'ESISA ANALYTICA'. Ce laboratoire est dédié à l'application de l'intelligence artificielle dans des domaines clés tels que la finance, la médecine, et l'écologie. Il offrira aux étudiants et chercheurs une plateforme innovante pour développer des projets de pointe dans l'analyse financière, les diagnostics médicaux, et la protection de l'environnement grâce à l'IA.",
    },
    {
      imgSrc: "images/actu/institut.jpg",
      altText: "Institut Francais",
      title: "Convention ESISA - Institut Francais",
      description:
        "Convention de partenariat entre ESISA et l’Institut francais de Fès signée hier en présence de Mme Carine Viallon, Consule générale de France à Fès, et M. Khalid Mekouar, Président-Directeur Général de l’ESISA.",
    },
    {
      imgSrc: "images/actu/capgemini.jpg",
      altText: "Capgemini",
      title: "Convention ESISA - Capgemini",
      description:
        "ESISA a conclu une convention avec Capgemini, leader mondial de la transformation digitale. L'accord vise à renforcer les partenariats école-entreprise via des programmes d'alternance, facilitant l'intégration professionnelle des étudiants.",
    },
    {
      imgSrc: "images/actu/isid.jpg",
      altText: "ISID-CO",
      title: "Convention ESISA - ISID-CO",
      description:
        "Signature d'une convention de partenariat entre l'ESISA et L'ISCID-CO, École étatique française affiliée à l'Université du Littoral Côte d'Opale avec 2 nouveaux masters, Management International et E-commerce et Cybersécurité.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="center-slider-wrap">
      <div className="container" style={{ width: "auto", zoom: "100%" }}>
        <div className="iq_heading_1 text-center">
          <h4>
            <span>ACTUALITÉS</span>
          </h4>
        </div>

        <Slider {...settings}>
          {actualites.map((actualite, index) => (
            <div className="center-dec" key={index}>
              <figure>
                <img className="actu" src={actualite.imgSrc} alt={actualite.altText} />
              </figure>
              <div className="text" style={{ height: "37rem" }}>
                <h5>
                  <a href="#">{actualite.title}</a>
                </h5>
                <p>{actualite.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
