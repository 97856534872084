import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './Objectifs_ESISA.css'; // Assurez-vous d'importer le fichier CSS

export default function Objectifs_ESISA() {
  return (

    <>
        <div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
            Objectifs de l'ESISA
          </span>
        </h4>
      </div>
      <div className="objectifs-container">
      <div className="objectifs-content">
      
        <div className="objectifs-details">
          <img style={{width:"16rem"}} src="images/images/img7.png" alt="Objectifs ESISA" className="objectifs-image" />
          <div className="objectifs-text">
            <p>
              Être demain ingénieur des systèmes d'information et de calcul, ce n'est pas seulement connaître l'informatique à travers la programmation, c'est surtout toutes les autres composantes, et acquérir les facultés indispensables d'abstraction et de formalisation. C'est l'objectif de la formation des ingénieurs de l'ESISA.
            </p>
            <p>
              ESISA est la grande école qui forme des ingénieurs aptes à concevoir des systèmes d'information. Considérée comme une discipline scientifique à part entière, les divers aspects fondamentaux de l'informatique sont étudiés. L'outil informatique dans les systèmes d'information devient de plus en plus complexe et se trouve immergé dans des environnements extrêmement variés. L'ingénieur doit donc acquérir trois sortes de compétences :
            </p>
            <p>
              L'Ecole Supérieure d'ingénierie en Sciences Appliquées est une école, fondée par un professionnel de l'informatique (Docteur, Ingénieur, Professeur Universitaire, PDG, Teneur du prix Sadi Carnot 1991), qui répond aux besoins du marché de travail de la région et de son développement.
            </p>
          </div>
        </div>

        <h5 className="objectifs-subheading">SAVOIR SPÉCIFIER CORRECTEMENT LES BESOINS EXPRIMÉS PAR L'UTILISATEUR :</h5>
        <div className="objectifs-text">
          <p>
            En effet, celui-ci énonce ses besoins dans les termes de son métier et souvent sans connaître les contraintes de l'informatique. L'ingénieur doit s'adapter, reformuler les besoins et faire preuve de qualités d'abstraction et de formalisation.
          </p>
        </div>

        <h5 className="objectifs-subheading">SAVOIR CONCEVOIR L'ARCHITECTURE DES SYSTÈMES À RÉALISER :</h5>
        <div className="objectifs-text">
          <p>
            L'ingénieur doit définir les différents constituants matériels et logiciels qu'il faudra assembler, ainsi que les relations entre eux. Cette tâche inclut la sélection des composants existants sur le marché et la définition des éléments à créer spécifiquement pour une application. Apprendre à anticiper les conséquences de ces choix en termes de performances, de fiabilité, de sécurité.
          </p>
        </div>

        <h5 className="objectifs-subheading">SAVOIR APPRÉHENDER LES CARACTÉRISTIQUES DES COMPOSANTS MATÉRIELS ET LOGICIELS :</h5>
        <img style={{marginTop:"2rem"}} src="images/images/president2.png" alt="Président" className="objectifs-image" />
        <div className="objectifs-text">
          <p>
            L'ingénieur doit savoir réaliser ou faire réaliser ces composants et apprécier ceux qui existent déjà. Une formation de haut niveau est donc nécessaire dans les domaines de l'informatique.
          </p>
        </div>
      </div>
      <NosPartenaires />
    </div>
    </>
   
  );
}
