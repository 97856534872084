import React from 'react'


import { Gallery, Item } from 'react-photoswipe-gallery'
export default function Galimg3() {
  return (
    <div id="filterable-item-holder-1" class="filterable_container main-gallery">
      <Gallery>
      <Item original="../images/images5/1(2).jpg"
        thumbnail="../images/images5/1(2).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/1(2).jpg" />
        
        )}
      </Item>
      <Item original="../images/images5/2(2).jpg"
        thumbnail="../images/images5/2(2).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/2(2).jpg" />
        
        )}
      </Item>
      <Item original="../images/images5/3(2).jpg"
        thumbnail="../images/images5/3(2).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/3(2).jpg" />
        
        )}
      </Item>
      <Item original="../images/images5/4(2).jpg"
        thumbnail="../images/images5/4(2).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/4(2).jpg" />
        
        )}
      </Item>
      <Item original="../images/images5/5(2).jpg"
        thumbnail="../images/images5/5(2).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/5(2).jpg" />
        
        )}
      </Item>
      <Item original="../images/images5/6(2).jpg"
        thumbnail="../images/images5/6(2).jpg"
        width="1024"
        height="768"
      >
        {({ ref, open }) => (
        
        <img ref={ref} onClick={open} width="464px" height="350px" src="../images/images5/6(2).jpg" />
        
        )}
      </Item>
      </Gallery>






      </div>
  )
}
