import React from 'react'
import NosPartenaires from '../../components/NosPartenaires';
export default function Ccna() {
  return (
	<div class="row">
    <div class="col-md-8">							  
    <div class="course_single_heading">				
        <div class="iq_heading_1 text-left">
            <h4><span style={{fontSize:'25px'}}>FORMATION CCNA</span></h4>
        </div>
        <div class="course-rating" style={{overflow: 'scroll',height:'1000px',overflowX: 'hidden'}}> 

        <p>Les systèmes et réseaux informatiques font désormais partie intégrante de tous les domaines des technologies de l'information. L’ingénierie des réseaux est maintenant une discipline bien établie qui intègre à la fois les connaissances théoriques des réseaux, que pratiques : Techniques de conception et méthodes de mise en oeuvre.

</p>
									<p>La certification académique CCNA (Cisco Certified Networking Academy) compte environ 80h de formation (cours, travaux pratiques, Examens) et s’adresse aux personnes désirant acquérir une formation solide dans le domaine des réseaux informatiques, l’interconnexion des réseaux et la mise en oeuvre de réseaux informatiques. Aucune connaissance dans le domaine des réseaux n’est requise.

</p>
									<p>Des certificats académiques Cisco seront délivrés aux stagiaires à la fin de la formation ainsi qu’une attestation de formation délivrée par la direction de l’ESISA.

</p>
									<p>Les cours seront animés par des experts du domaine certifiés en tant qu’instructeur sur les produits objets de cette formation. Les cours sont composés de plusieurs chapitres. Chaque chapitre commencera par un exposé théorique, où le formateur explique les fondements ou les fonctionnalités étudiées. Ces exposés seront appuyés par des manipulations pratiques par les stagiaires afin qu’ils assimilent au mieux les concepts vus dans le cours.

</p>
									<h5 style={{color:'#1962e5'}}>CONTENU DE LA CERTIFICATION CISCO CCNA</h5>
                      <div class="curriculum-table iqoniq-table">
													<ul class="table-row table-header">
													    <li><div>Certification Cisco, Technologie des reseaux informatiques</div></li>
													  
													</ul>
													<ul class="table-row">
													    <li><div>Technologies de base des réseaux (15h)</div></li>
													     <li class="course-name">
													    	<div><a href="#">Cours CCNA1</a></div>
												    	</li>

													</ul>
													<ul class="table-row">
													    <li><div>Technologies pour le routage (12h)	</div></li>
													    <li class="course-name">
													    	<div><a href="#">Cours CCNA2</a></div>
												    	</li>

													</ul>
																<ul class="table-row">
													    <li><div>Technologies pour la commutation "switching"(12h)		</div></li>
													    <li class="course-name">
													    	<div><a href="#">Cours CCNA3</a></div>
												    	</li>

													</ul>
																<ul class="table-row">
													    <li><div>Technologies des reseaux WANs (12h)	</div></li>
													    <li class="course-name">
													    	<div><a href="#">Cours CCNA4</a></div>
												    	</li>

													</ul>
													
												</div>
       </div>		
      </div>
      </div>
      <NosPartenaires />
                         
                              
	  </div>
)
}