import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import NosPartenaires from '../../components/NosPartenaires';
import { useNavigate } from 'react-router-dom';
import "./indexx.css";

const SITE_KEY = '6LfhAQUqAAAAAGrtFFy0MRPFpw79Q35ymKgUZH-v';

export default function Preinscription() {
  const [autreVisible, setAutreVisible] = useState(false);
  const [autreValue, setAutreValue] = useState('');
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [captchaToken, setCaptchaToken] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-16632715756');
  }, []);

  const handleBaccalaureatChange = (event) => {
    const { value } = event.target;
    setAutreVisible(value === '');
    if (value !== '') {
      setAutreValue('');
    }
  };

  const handleAutreChange = (event) => {
    setAutreValue(event.target.value);
  };

  const onChangeCaptcha = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (!captchaToken) {
      alert('Veuillez vérifier que vous n\'êtes pas un robot.');
      return;
    }

    if (autreVisible && autreValue) {
      formData.set('baccalaureat', autreValue);
    }

    setIsLoading(true);
    setLoadingPercentage(0);

    try {
      formData.append('recaptchaToken', captchaToken);

      const xhr = new XMLHttpRequest();

      xhr.open('POST', 'https://esisa-portail-intranet-back.vercel.app/contact/info');

     // xhr.open('POST', 'http://localhost:4000/contact/info', true);


      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setLoadingPercentage(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          setLoadingPercentage(100);
          setNotification('Formulaire envoyé avec succès !');
          event.target.reset();
          setAutreVisible(false);
          setShowModal(true);
        } else {
          throw new Error('Erreur lors de l\'envoi du formulaire.');
        }
        setIsLoading(false);
      };

      xhr.onerror = () => {
        setLoadingPercentage(0);
        console.error('Erreur lors de l\'envoi du formulaire.');
        setNotification('Une erreur est survenue lors de l\'envoi du formulaire.');
        setIsLoading(false);
      };

      xhr.send(formData);
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire :', error);
      setNotification('Une erreur est survenue lors de l\'envoi du formulaire.');
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  return (
    <div style={{textAlign:"start"}} className="row">
      <div className="col-md-8">
        <div className="course_single_heading">
          <div className="iq_heading_1 text-left">
            <h4><span style={{ fontSize: '25px' }}>PRE-INSCRIPTION</span></h4>
          </div>

          <div className="course-rating">
            <form className="form" id="contact_form" name="contact_form" onSubmit={handleSubmit}>
              <div className="col-md-12">
                <div className="iq-input" style={{marginBottom:"2rem"}}>
                  <label style={{marginTop:"2rem"}}>Genre <font color="red">*</font></label><br />
                  <input type="radio" name="genre" value="Homme" defaultChecked /> Homme
                  &nbsp;&nbsp;&nbsp;
                  <input type="radio" name="genre" value="Femme" /> Femme
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="iq-input">
                  <label>Nom <font color="red">*</font></label>
                  <input type="text" name="nom" required />
                </div>
              </div>
              <div className="col-md-6 col-sm-6" style={{marginBottom:"2rem"}}>
                <div className="iq-input">
                  <label>Prénom <font color="red">*</font></label>
                  <input type="text" name="prenom" required />
                </div>
              </div>
              <div className="col-md-6 col-sm-6" style={{marginBottom:"2rem"}}>
                <div className="iq-input">
                  <label>Email <font color="red">*</font></label>
                  <input type="email" name="email" required />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="iq-input">
                  <label>Téléphone <font color="red">*</font></label>
                  <input type="text" name="tel" required />
                </div>
              </div>
              <div className="col-md-12" style={{marginBottom:"2rem"}}>
                <div className="iq-input">
                  <label>Type de Baccalauréat <font color="red">*</font></label>
                  <input type="radio" name="baccalaureat" value="Sciences Mathématiques" defaultChecked onChange={handleBaccalaureatChange} /> Sciences Mathématiques<br />
                  <input type="radio" name="baccalaureat" value="Sciences Physiques" onChange={handleBaccalaureatChange} /> Sciences Physiques<br />
                  <input type="radio" name="baccalaureat" value="Sciences de la Vie et de la Terre" onChange={handleBaccalaureatChange} /> Sciences de la Vie et de la Terre<br />
                  <input type="radio" name="baccalaureat" value="Sciences Economiques" onChange={handleBaccalaureatChange} /> Sciences Economiques<br />
                  <input type="radio" name="baccalaureat" value="Technique" onChange={handleBaccalaureatChange} /> Technique<br />
                  <input type="radio" name="baccalaureat" value="" onChange={handleBaccalaureatChange} /> Autre :<br />
                </div>
              </div>
              <div className="col-md-12" style={{marginBottom:"2rem", display: autreVisible ? 'block' : 'none' }}>
                <div style={{marginTop:"5px"}} className="iq-input">
                  <label>Veuillez préciser :</label>
                  <input type="text" name="autretxt" placeholder="" value={autreValue} onChange={handleAutreChange} />
                </div>
              </div>
              <div className="col-md-12" style={{marginBottom:"2rem"}}>
                <div className="iq-input">
                  <label>Choisissez une filière <font color="red">*</font></label>
                  <select name="filiere" required defaultValue="Bac +3 Ingénierie Logicielle">
                    <option value="" disabled>Veuillez choisir une filière</option>
                    <option value="Bac +3 Ingénierie Logicielle (IL)">Bac +3 Ingénierie Logicielle (IL)</option>
                    <option value="Bac +5 Ingénierie des Systèmes d'Information (ISI)">Bac +5 Ingénierie des Systèmes d'Information (ISI)</option>
                    <option value="Bac +5 Ingénierie des Systèmes Distribués (ISD)">Bac +5 Ingénierie des Systèmes Distribués (ISD)</option>
                    <option value="Bac +5 Ingénierie des Systèmes d'Information appliquées à la Transformation Numérique (ISITN)">Bac +5 Ingénierie des Systèmes d'Information appliquées à la Transformation Numérique (ISITN)</option>
                    <option value="Bac +5 Ingénierie des Systèmes d'Information appliquées à l'Intelligence Artificielle (IS2IA)">Bac +5 Ingénierie des Systèmes d'Information appliquées à l'Intelligence Artificielle (IS2IA)</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="iq-input">
                  <label>Moyenne Générale ex: 14.25 <font color="red">*</font></label>
                  <input type="text" name="maths" maxLength="5" required />
                </div>
              </div>
              <div className="col-md-12">
                <ReCAPTCHA
                  sitekey={SITE_KEY}
                  onChange={onChangeCaptcha}
                />
              </div>
              <div className="col-md-12">
                {notification && <div className="notification">{notification}</div>}
              </div>
              <div className="col-md-12" style={{textAlign:"center"}}>
                <input style={{marginTop:"1rem"}} className="iq_link_1 btn-modern" type="submit" value="Envoyer" />
              </div>
            </form>
            {isLoading && (
  <div className="spinner-overlay">
    <div className="spinner-container">
      {/* <div className="loading-percentage">
        {loadingPercentage}%
      </div> */}
      <div className="custom-spinner" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
)}

          </div>
        </div>
      </div>
      <NosPartenaires />
      {showModal && (
        <div className="modal" style={modalStyle}>
          <div className="modal-content" style={modalContentStyle}>
            <button className="close" onClick={handleModalClose} style={closeButtonStyle}>&times;</button>
            <h2 style={modalHeaderStyle}>Préinscription Réussie</h2>
            <p style={modalTextStyle}>
              Félicitations, votre préinscription a été réalisée avec succès ! Vous recevrez sous peu un email de confirmation.
            </p>
            <br />
            <strong>
              <p style={modalTextStyle2}>
                @COPYRIGHT © 2024 ESISA - TOUS DROITS RÉSERVÉS
              </p>
            </strong>
          </div>
        </div>
      )}
    </div>
  );
}

const modalStyle = {
  position: 'fixed',
  zIndex: 1000,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  width: '90%',
  maxWidth: '500px',
  textAlign: 'center',
  position: 'relative',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'none',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: 'red',
  transition: 'color 0.3s',
};

const modalHeaderStyle = {
  fontSize: '24px',
  color: '#333',
  marginBottom: '15px',
};

const modalTextStyle = {
  fontSize: '16px',
  color: '#555',
  lineHeight: '1.5',
};

const modalTextStyle2 = {
  fontSize: '12px',
  color: '#555',
  lineHeight: '1.5',
};

closeButtonStyle[':hover'] = {
  color: '#000',
};
